import {
    Box,
    Flex,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Portal,
    Spinner,
    Text,
    Tooltip,
    useToast,
    UseToastOptions,
    Image,
    Link,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { IMMERSION_SIZES } from "../../Immersion";
import {
    chimeMicTooltip,
    currentAudioInputDeviceId,
    currentVideoInputDeviceId,
    localAudioTrackState,
    localDevicesState,
    videoPermissionState,
} from "../../immersionState";
import { useLocalAudio } from "../hooks/useLocalAudio";
import { useLocalAudioNoiseCancellation } from "../hooks/useLocalAudioNoiseCancellation";
import { useLocalVideo } from "../hooks/useLocalVideo";
import { useLocalVideoBlur } from "../hooks/useLocalVideoBlur";
import MicLevelButton from "./MicLevelButton";
import { CameraButton } from "./CameraButton";
import { useVideoDevices } from "../hooks/useVideoDevices";
import AudioErrorIcon from "../../../assets/audio_error.svg";
import { useChimeModals } from "../hooks/useChimeModals";
import { useIsTalkingWhileMuted } from "../hooks/useIsTalkingWhileMuted";

export const AudioVideoButtons: React.FC<{ videoWidth: number }> = ({
    videoWidth,
}) => {
    const videoDevices = useVideoDevices();
    const hasVideoDevice = videoDevices.length !== 0;
    const videoPermission = useRecoilValue(videoPermissionState);
    const { cameraAccessModal, cameraPermissionsModal } = useChimeModals();
    const [micTooltipValue, setMicTooltipValue] =
        useRecoilState(chimeMicTooltip);
    const {
        toggleVideo,
        isVideoEnabled,
        isLoading: localVideoLoading,
    } = useLocalVideo();
    const {
        isAudioEnabled,
        toggleMute,
        isLoading: localAudioLoading,
    } = useLocalAudio();
    const { canBlur, isBackgroundBlur, toggleBackgroundBlur } =
        useLocalVideoBlur();
    const { isNoiseCancelling, toggleNoiseCancelling } =
        useLocalAudioNoiseCancellation();
    const devices = useRecoilValue(localDevicesState);
    const audioTrack = useRecoilValue(localAudioTrackState);
    const isTalkingWhileMuted = useIsTalkingWhileMuted();

    useEffect(() => {
        if (isTalkingWhileMuted && !micTooltipValue) {
            setMicTooltipValue(
                "Are you talking? Your mic is off. Click the mic to turn it on.",
            );
            return () => {
                setTimeout(() => {
                    setMicTooltipValue("");
                }, 3000);
            };
        }
    }, [isTalkingWhileMuted, micTooltipValue]);

    const [currentAIDeviceId, setCurrentAudioInputDeviceId] = useRecoilState(
        currentAudioInputDeviceId,
    );
    const [currentVIDeviceId, setCurrentVideoInputDeviceId] = useRecoilState(
        currentVideoInputDeviceId,
    );

    const toast = useToast();
    let aIToastId: string | number | undefined,
        aOToastId: string | number | undefined,
        vIToastId: string | number | undefined;

    const getToastObject = (
        deviceInfo: MediaDeviceInfo,
        deviceType: string,
    ): UseToastOptions => {
        return {
            position: "top",
            duration: 5000,
            containerStyle: {
                maxWidth: "100%",
            },
            render: () => (
                <Box
                    width="max-content"
                    background="rgba(60, 62, 68, .7)"
                    top={`calc(${IMMERSION_SIZES.HEADER_HEIGHT} + 4px)`}
                    position="relative"
                    padding="12px 8px"
                    borderRadius="4px"
                    color="white"
                >
                    Your {`${deviceInfo.label}`} {deviceType} changed to{" "}
                    <b>{deviceInfo.label}</b>.
                </Box>
            ),
        };
    };

    const handleChangeAudioInput = (device: MediaDeviceInfo) => {
        try {
            navigator.mediaDevices
                .getUserMedia({
                    audio: { deviceId: { exact: device.deviceId } },
                })
                .then((stream) => {
                    setCurrentAudioInputDeviceId(device.deviceId);
                    if (aIToastId) {
                        toast.close(aIToastId);
                    }
                    aIToastId = toast(getToastObject(device, "microphone"));
                    stream.getAudioTracks().forEach((_) => _.stop());
                })
                .catch((e) => {
                    Sentry.captureMessage("Failed to change audio track");
                    Sentry.captureException(e);
                    throw e;
                });
        } catch (e) {
            Sentry.captureMessage("Failed to change audio track");
            Sentry.captureException(e);
            throw e;
        }
    };

    const handleChangeVideoInput = (device: MediaDeviceInfo) => {
        try {
            navigator.mediaDevices
                .getUserMedia({
                    video: { deviceId: { exact: device.deviceId } },
                })
                .then((stream) => {
                    setCurrentVideoInputDeviceId(device.deviceId);
                    if (vIToastId) {
                        toast.close(vIToastId);
                    }
                    vIToastId = toast(getToastObject(device, "camera"));
                    stream.getVideoTracks().forEach((_) => _.stop());
                })
                .catch((e) => {
                    Sentry.captureMessage("Failed to change video track");
                    Sentry.captureException(e);
                    throw e;
                });
        } catch (e) {
            Sentry.captureMessage("Failed to change video track");
            Sentry.captureException(e);
            throw e;
        }
    };

    return (
        <Flex
            border="1px solid"
            borderRadius="6px"
            boxShadow="6px 6px 14px rgba(0, 0, 0, 0.04)"
            borderColor="gray.100"
            w="100%"
            maxW={videoWidth}
            alignItems="center"
            justifyContent="center"
            p="2px"
            mb="4px"
        >
            <Tooltip
                label={micTooltipValue}
                isOpen={!!micTooltipValue}
                hasArrow
                bg="gray.800"
                color="gray.100"
                textAlign={"left"}
                borderRadius={"4px"}
                p={"8px"}
                placement="bottom"
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                lineHeight="17px"
                padding="16px"
                w="206px"
            >
                <Flex
                    position="relative"
                    direction="column"
                    w="100%"
                    h="100%"
                    p="2px"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="4px"
                    _hover={{ backgroundColor: "gray.50" }}
                    onClick={(e) => {
                        if (
                            (e.target as HTMLElement).getAttribute(
                                "data-id",
                            ) === "audio-menu-button" ||
                            (e.target as HTMLElement).matches(
                                "[data-id='audio-menu'] *",
                            )
                        ) {
                            return;
                        }

                        toggleMute();
                    }}
                    cursor="pointer"
                    aria-label={"Toggle audio mute"}
                >
                    <Menu>
                        <MenuButton
                            data-id="audio-menu-button"
                            position="absolute"
                            top="2px"
                            right="2px"
                            padding="4px"
                            borderRadius="4px"
                            color="#808080"
                            _hover={{
                                backgroundColor: "gray.200",
                                color: "gray.800",
                            }}
                            aria-label={"Open audio devices dropdown"}
                        >
                            <ChevronDownIcon height="14px" />
                        </MenuButton>
                        <Portal>
                            <MenuList
                                zIndex={20}
                                right={0}
                                data-id="audio-menu"
                            >
                                <MenuOptionGroup
                                    fontWeight="extrabold"
                                    title="Select Microphone"
                                    value={`${currentAIDeviceId}`}
                                >
                                    {devices
                                        .filter(
                                            (device) =>
                                                device.kind === "audioinput",
                                        )
                                        .map((device) => (
                                            <MenuItemOption
                                                key={device.deviceId}
                                                value={device.deviceId}
                                                _checked={{
                                                    color: "blue.500",
                                                }}
                                                onClick={() =>
                                                    handleChangeAudioInput(
                                                        device,
                                                    )
                                                }
                                            >
                                                {device.label.split("(")[0]}
                                            </MenuItemOption>
                                        ))}
                                </MenuOptionGroup>
                                <MenuOptionGroup
                                    fontWeight="extrabold"
                                    title="Microphone Connect Options"
                                    value={`${isNoiseCancelling}`}
                                >
                                    <MenuItemOption
                                        value="true"
                                        onClick={() => {
                                            toggleNoiseCancelling();
                                        }}
                                    >
                                        Noise Cancellation
                                    </MenuItemOption>
                                </MenuOptionGroup>
                            </MenuList>
                        </Portal>
                    </Menu>

                    <Flex shrink={0} borderRadius="4px" overflowX="hidden">
                        {localAudioLoading ? (
                            <Spinner size={"xs"} />
                        ) : (
                            <MicLevelButton
                                micEnabled={isAudioEnabled}
                                localAudioTrack={audioTrack}
                            />
                        )}
                    </Flex>

                    <Text
                        fontFamily="Inter"
                        fontSize="10px"
                        fontWeight="400"
                        lineHeight="12px"
                        textAlign="center"
                        mt="4px"
                    >
                        {isAudioEnabled ? "Mute" : "Unmute"}
                    </Text>
                </Flex>
            </Tooltip>

            <Flex
                position="relative"
                direction="column"
                w="100%"
                h="100%"
                p="2px"
                justifyContent="center"
                alignItems="center"
                borderRadius="4px"
                _hover={{
                    backgroundColor: "gray.50",
                }}
                onClick={(e) => {
                    if (videoPermission === "denied") {
                        return cameraPermissionsModal.onOpen();
                    }
                    if (!hasVideoDevice) {
                        return cameraAccessModal.onOpen();
                    }
                    if (
                        (e.target as HTMLElement).getAttribute("data-id") ===
                            "video-menu-button" ||
                        (e.target as HTMLElement).matches(
                            "[data-id='video-menu'] *",
                        )
                    ) {
                        return;
                    }

                    toggleVideo();
                }}
                aria-label={"Toggle video"}
                cursor="pointer"
            >
                <Menu>
                    <MenuButton
                        disabled={
                            !hasVideoDevice || videoPermission === "denied"
                        }
                        data-id="video-menu-button"
                        position="absolute"
                        top="2px"
                        right="2px"
                        padding="4px"
                        borderRadius="4px"
                        color="#808080"
                        _hover={{
                            backgroundColor: "gray.200",
                            color: "gray.800",
                        }}
                        aria-label={"Open video devices dropdown"}
                    >
                        <ChevronDownIcon height="14px" />
                    </MenuButton>
                    <Portal>
                        <MenuList zIndex={20} right="0" data-id="video-menu">
                            <MenuOptionGroup
                                value={`${currentVIDeviceId}`}
                                fontWeight="extrabold"
                                title="Select a Camera"
                            >
                                {devices
                                    .filter(
                                        (device) =>
                                            device.kind === "videoinput",
                                    )
                                    .map((device) => (
                                        <MenuItemOption
                                            key={device.deviceId}
                                            value={device.deviceId}
                                            _checked={{
                                                color: "blue.500",
                                            }}
                                            onClick={() =>
                                                handleChangeVideoInput(device)
                                            }
                                        >
                                            {device.label.split("(")[0]}
                                        </MenuItemOption>
                                    ))}
                            </MenuOptionGroup>
                            {canBlur ? (
                                <MenuOptionGroup
                                    value={`${isBackgroundBlur}`}
                                    fontWeight="extrabold"
                                    title="Video Connect Options"
                                >
                                    <MenuItemOption
                                        value="true"
                                        onClick={() => {
                                            toggleBackgroundBlur();
                                        }}
                                    >
                                        Blur my background
                                    </MenuItemOption>
                                </MenuOptionGroup>
                            ) : null}
                        </MenuList>
                    </Portal>
                </Menu>
                {!hasVideoDevice && videoPermission === "denied" && (
                    <Tooltip
                        hasArrow
                        placement="bottom"
                        label={
                            videoPermission === "denied"
                                ? "Allow access to camera"
                                : null
                        }
                        fontFamily="Inter"
                        fontSize="12px"
                        fontWeight="400"
                        bg="gray.800"
                        color="gray.100"
                        padding="8px"
                        textAlign={"center"}
                        borderRadius={"4px"}
                        p={"8px"}
                    >
                        <Image
                            src={AudioErrorIcon}
                            w="16px"
                            h="16px"
                            alt={"Video device alert"}
                            position={"absolute"}
                            top={"-8px"}
                            right={6}
                        />
                    </Tooltip>
                )}
                {!hasVideoDevice && videoPermission !== "denied" ? (
                    <Tooltip
                        hasArrow
                        closeDelay={3000}
                        placement="right"
                        label={
                            <Text>
                                Having trouble with your video? Try refreshing
                                your browser or{" "}
                                <Link
                                    textDecoration="underline"
                                    fontWeight="600"
                                    onClick={() => {
                                        // @ts-ignore
                                        window.$chatwoot.toggle();
                                    }}
                                >
                                    get live support
                                </Link>
                                .
                            </Text>
                        }
                        fontFamily="Inter"
                        fontSize="12px"
                        fontWeight="400"
                        bg="gray.800"
                        color="gray.100"
                        padding="8px"
                        textAlign={"left"}
                        borderRadius={"4px"}
                        p={"8px"}
                        pointerEvents={"all"}
                    >
                        <Image
                            src={AudioErrorIcon}
                            w="16px"
                            h="16px"
                            alt={"Video device alert"}
                            position={"absolute"}
                            top={"-8px"}
                            right={6}
                        />
                    </Tooltip>
                ) : null}
                {localVideoLoading ? (
                    <Spinner size="xs" />
                ) : (
                    <CameraButton isActive={isVideoEnabled} />
                )}
                <Text
                    fontFamily="Inter"
                    fontSize="10px"
                    fontWeight="400"
                    lineHeight="12px"
                    textAlign="center"
                    mt="4px"
                >
                    {isVideoEnabled ? "Stop Video" : "Start Video"}
                </Text>
            </Flex>
        </Flex>
    );
};
