import {
    DefaultMeetingSession,
    EventAttributes,
    EventName,
} from "amazon-chime-sdk-js";
import {
    BaseMetadata,
    BaseUserMeta,
    Json,
    LsonObject,
    Room as LiveblocksRoom,
} from "@liveblocks/client";
import { Presence } from "shared/LiveblocksTypes";
import { RosterType } from "./video_chime/hooks/useRoster";
import { atom } from "jotai";

/**
 * CHIME RELATED ATOMS
 */

type MeetingEventProviderContextType =
    | {
          name: EventName;
          attributes: EventAttributes;
      }
    | undefined;

export const meetingEventState =
    atom<MeetingEventProviderContextType>(undefined);

export const meetingSession = atom<DefaultMeetingSession | null>(null);

export const isVideoEnabledState = atom(true);

export const isAudioEnabledState = atom(true);

export const isBackgroundBlurEnabledState = atom(false);

export const isBackgroundBlurRequestedState = atom(false);

export const backgroundBlurSupportedState = atom<boolean | undefined>(
    undefined,
);

export const isNoiseCancellingEnabledState = atom(true);

export const hasReachedVideoLimitState = atom(false);

export const tileIdState = atom(null as number | null);

export const rosterState = atom<RosterType>({});

export const chimeMicTooltip = atom<string | null>(null);

/**
 * END OF CHIME RELATED ATOMS
 */

export const liveblocksRoom = atom<LiveblocksRoom<
    Presence,
    LsonObject,
    BaseUserMeta,
    Json,
    BaseMetadata
> | null>(null);

export const stepSidebarOpen = atom<boolean>(false);

export const micTooltip = atom<string | null>(null);

export const streamSource = atom<StreamSource>("sparkwise");

export const newTabHelpUrl = atom<string | null>(null);

export const currentAudioInputDeviceId = atom<string | null>(null);
export const currentAudioOutputDeviceId = atom<string | null>(null);
export const currentVideoInputDeviceId = atom<string | null>(null);

/**
 * @deprecated
 */
export const localVideoTrackState = atom<MediaStreamTrack | null>(null);

export const localAudioTrackState = atom<MediaStreamTrack | null>(null);

export const videoPermissionState = atom<
    "prompt" | "denied" | "granted" | null
>(null);

export const audioPermissionState = atom<
    "prompt" | "denied" | "granted" | null
>(null);

/**
 * List of media devices
 */
export const localDevicesState = atom<MediaDeviceInfo[]>([]);

export enum ProductTourStep {
    OrientationVideo = 0,
    Intro = 1,
    Workspace = 2,
    Video = 3,
    ProgressTracker = 4,
    Instructions = 5,
    Timer = 6,
    End = 7,
}
export const productTourStep = atom<ProductTourStep>(
    ProductTourStep.OrientationVideo,
);

export type StreamSource = "sparkwise" | "cloudflare";

export const mementoFormSubmitted = atom<boolean>(false);
