import { Button, ButtonGroup, Flex, Text } from "@chakra-ui/react";
import React, { ReactElement, useEffect, useRef, useState } from "react";

export const ExitModal: React.FC<{
    show: boolean;
    icon?: ReactElement;
    title: string;
    label: ReactElement | string;
    stayButtonLabel?: string | ReactElement;
    exitButton?: ReactElement;
    onStay?: () => void;
    id?: string;
}> = ({
    show,
    icon,
    title,
    label,
    onStay,
    exitButton,
    stayButtonLabel,
    id,
}) => {
    const [elementHeight, setElementHeight] = useState<number | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const height = ref.current?.getBoundingClientRect().height;
        if (height) {
            setElementHeight(height);
        }
    }, [ref]);
    return (
        <Flex
            ref={ref}
            width="100%"
            left="0"
            right="0"
            position="fixed"
            bottom={show ? "0" : `-${elementHeight}px`}
            bg="rgba(255, 255, 255, .9)"
            boxShadow={
                show ? "0px -8px 24px 0px rgba(0, 0, 0, 0.04);" : undefined
            }
            alignItems={"center"}
            justifyContent={"center"}
            pt="32px"
            pb="16px"
            backdropFilter={"blur(5px)"}
            transition={"bottom 0.3s ease-in-out"}
            className={"exit-modal"}
            id={id ? `exit-modal-` + id : undefined}
        >
            <Flex pb="14px">
                <Flex>{icon}</Flex>
                <Flex ml="24px" flexDirection="column">
                    <Text
                        color="gray.800"
                        fontSize={"24px"}
                        fontWeight={600}
                        mb="12px"
                    >
                        {title}
                    </Text>
                    {label}
                </Flex>
            </Flex>
            <Flex position="absolute" bottom="20px" right="20px">
                <ButtonGroup>
                    {onStay ? (
                        <Button variant={"outline"} onClick={onStay}>
                            {stayButtonLabel || "Stay"}
                        </Button>
                    ) : null}
                    {exitButton}
                </ButtonGroup>
            </Flex>
        </Flex>
    );
};
