import { useRecoilState } from "recoil";
import { Box, Flex, Text, Tooltip, keyframes } from "@chakra-ui/react";
import {
    useShouldShowHelp,
    useStepContent,
    useIsProductTour,
} from "./hooks/useSessionContent";
import {
    productTourStep as productTourStepState,
    ProductTourStep,
} from "./immersionState";
import { Timer } from "./Timer";
import { StepHelp } from "./StepHelp";
import { Hotspot } from "./Hotspot";
import ReactMarkdown from "react-markdown";
import { ProductTourTooltip } from "./ProductTourTooltip";
import { PRODUCT_TOUR_TOOLTIPS } from "../Constants";
import { IMMERSION_SIZES } from "./Immersion";

const slideLeft = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
`;

const productTourContent = {
    menuTitle: "Product Tour",
    instructions: "You will find instructions here!",
    participation: "GROUP",
    duration: 120, // This must be 2 mins in order to match the step content for "Intro video guided tour"
};

export const LeftSidebar = () => {
    const [productTourStep, setProductTourStep] =
        useRecoilState(productTourStepState);
    const isProductTour = useIsProductTour();
    const shouldShowHelp = useShouldShowHelp();
    const stepContent = useStepContent();

    const content = isProductTour ? productTourContent : stepContent;
    const isGroupStep = content.participation === "GROUP";
    const showHotspotToRevealInstructions =
        isProductTour && productTourStep === ProductTourStep.ProgressTracker;
    const showHotspotToRevealTimer =
        isProductTour && productTourStep === ProductTourStep.Instructions;
    const hideInstructions =
        isProductTour && productTourStep < ProductTourStep.ProgressTracker;
    const showTimer = isProductTour
        ? productTourStep >= ProductTourStep.Timer
        : !!content?.duration;

    return (
        <Tooltip
            borderRadius="6px"
            bg="gray.800"
            label={
                <ProductTourTooltip
                    header="Instructions"
                    description={
                        PRODUCT_TOUR_TOOLTIPS[ProductTourStep.Instructions]
                    }
                />
            }
            hasArrow
            placement="right-start"
            offset={[0, 16]}
            isOpen={
                isProductTour &&
                productTourStep === ProductTourStep.Instructions
            }
        >
            <Flex
                w="20vw"
                visibility={hideInstructions ? "hidden" : "visible"}
                flexDirection="column"
                justifyContent="space-between"
                marginTop={IMMERSION_SIZES.STEP_MARGIN_TOP}
            >
                {showHotspotToRevealInstructions ? (
                    <Hotspot
                        onClick={() =>
                            setProductTourStep(ProductTourStep.Instructions)
                        }
                    />
                ) : (
                    <Box
                        key={content.menuTitle}
                        animation={`${slideLeft} 1s`}
                        padding="12px"
                        borderColor="gray.200"
                        borderWidth="1px"
                        width="100%"
                        borderRadius="12px"
                        boxShadow="0px 16px 24px -4px rgba(6, 20, 45, .08)"
                        bgColor="white"
                    >
                        <Text
                            marginBottom="16px"
                            bg={isGroupStep ? "gray.100" : "info.0"}
                            color="gray.900"
                            width="fit-content"
                            padding="6px 8px"
                            borderRadius="6px"
                            fontWeight="700"
                            fontSize="10px"
                            lineHeight="normal"
                        >
                            {content.participation === "GROUP"
                                ? "GROUP WORK"
                                : "SOLO WORK"}
                        </Text>

                        <Box
                            marginBottom="36px"
                            fontSize="md"
                            fontWeight="normal"
                        >
                            <ReactMarkdown className="exclude-from-reset richtext">
                                {`${content.instructions}`}
                            </ReactMarkdown>
                        </Box>
                        {shouldShowHelp ? <StepHelp /> : null}
                        {showHotspotToRevealTimer && (
                            <Hotspot
                                onClick={() => {
                                    setProductTourStep(ProductTourStep.Timer);
                                }}
                            />
                        )}
                        {showTimer && <Timer total={content.duration!} />}
                    </Box>
                )}
            </Flex>
        </Tooltip>
    );
};
