import { Button, Text } from "@chakra-ui/react";
import { ReactComponent as LinkIcon } from "design/assets/ExternalLinkIcon.svg";
import { useImmersionPostSessionRedirect } from "../hooks/useImmersion";
import { ExitModal } from "./ExitModal";

export const RedirectModal: React.FC<{
    show: boolean;
    onStay: () => void;
}> = ({ show, onStay }) => {
    const red = useImmersionPostSessionRedirect();
    return (
        <ExitModal
            icon={<LinkIcon width={"92px"} height={"92px"} />}
            show={show}
            title={"Heads up!"}
            label={
                <Text color="gray.500" fontSize={"16px"} fontWeight={400}>
                    Now, you will be taken to{" "}
                    <Text fontWeight={600} display="inline">
                        {red?.name}
                    </Text>
                    {"."}
                </Text>
            }
            onStay={onStay}
            exitButton={
                <Button
                    colorScheme="blue"
                    onClick={() => {
                        if (red?.url) {
                            window.location.assign(red.url);
                        }
                    }}
                >
                    Proceed
                </Button>
            }
            id={"redirect-modal"}
        />
    );
};
