import { trpc } from "./useTRPC";
import * as Sentry from "@sentry/react";

export const useAccount = () => {
    const accountInfo = trpc.account.getAccountInfo.useQuery();
    if (accountInfo.error) {
        Sentry.captureException(accountInfo.error);
    }
    return accountInfo;
};

export const useChatwootToken = () => {
    const { data, error } = trpc.account.getChatwootToken.useQuery();
    if (error) {
        Sentry.captureException(error);
    }
    return data;
};

export const useAreVideosRecorded = () => {
    const user = useAccount();
    return user?.data?.organization.featureFlagOverrides.some(
        (flag) => flag.featureFlag.name === "Record sessions" && flag.enabled,
    );
};

export const useAreRecordingsTranscribed = () => {
    const user = useAccount();
    return user?.data?.organization.featureFlagOverrides.some(
        (flag) => flag.featureFlag.name === "Transcribe conversations" && flag.enabled,
    );
};

export const useParticipantType = () => {
    const { data: account } = useAccount();
    const isSelfServiceTrial =
        account?.organization?.name === "Sparkwise Trial";
    const isTrialAccount = !account?.organization?.featureFlagOverrides.some(
        (flag) => flag.featureFlag.name === "Customer" && flag.enabled,
    );
    return isSelfServiceTrial || isTrialAccount ? "Trial account" : "Customer";
};
