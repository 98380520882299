import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useRoom as useLiveblocksRoom } from "../liveblocks.config";
import { liveblocksRoom as liveblocksRoomState } from "./immersionState";

export const RoomSetter = (props: { children: React.ReactNode }) => {
    const { children } = props;
    const liveblocksRoom = useLiveblocksRoom();
    const setLiveblocksRoom = useSetAtom(liveblocksRoomState);

    useEffect(() => {
        if (!liveblocksRoom) return;

        setLiveblocksRoom(liveblocksRoom);
    }, [liveblocksRoom]);

    return children;
};
