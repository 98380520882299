import { Avatar, AvatarGroup, Box, Flex, Tooltip } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import {
    PARTICIPANT_BACKGROUNDS,
    PRODUCT_TOUR_TOOLTIPS,
} from "../../Constants";
import ProgressTrackerVideo from "../../assets/ProgressTrackerVideo.mp4";
import { ProductTourTooltip } from "../ProductTourTooltip";
import { useSelfData } from "../hooks/useImmersion";
import { useStepToParticipants } from "../hooks/usePresence";
import { useIsProductTour } from "../hooks/useSessionContent";
import {
    ProductTourStep,
    productTourStep as productTourStepState,
} from "../immersionState";

type Props = {
    index: number;
    gatherPromptAnchorRef: React.RefObject<HTMLDivElement>;
};

export const TimelineAvatars = (props: Props) => {
    const { index, gatherPromptAnchorRef } = props;
    const stepToParticipants = useStepToParticipants();
    const self = useSelfData();

    const isProductTour = useIsProductTour();
    const productTourStep = useAtomValue(productTourStepState);

    const numParticipantsOnStep = stepToParticipants[index]?.length || 0;
    const avatarGroupContainsSelf = stepToParticipants[index]?.some(
        (participant) => participant.id === self?.sessionParticipantUuid,
    );

    return (
        <Box marginX="auto" position="relative">
            <Tooltip
                label={stepToParticipants[index]
                    ?.map((participant) => participant.user.firstName)
                    .join(", ")}
                hasArrow
                placement="top"
            >
                <AvatarGroup
                    marginX="auto"
                    size="2xs"
                    spacing="-8px"
                    bottom="14px"
                    position="absolute"
                    transform="translate(-50%, 0)"
                    opacity={avatarGroupContainsSelf ? 1 : 0.6}
                    {...(numParticipantsOnStep > 0 && {
                        // make the avatar group have a straight edge top/bottom outline
                        _before: {
                            content: '""',
                            position: "absolute",
                            top: "0",
                            left: "50%",
                            width: `calc(100% - ${
                                100 / numParticipantsOnStep
                            }% - 5px)`,
                            height: "20px",
                            transform: "translateX(-50%)",
                            bg: "gray.800",
                        },
                        // add an arrow to the bottom of the avatar group
                        _after: {
                            content: '""',
                            position: "absolute",
                            bottom: "3px",
                            left: "50%",
                            width: "8px",
                            height: "8px",
                            transform: "translate(-50%, 50%) rotate(45deg)",
                            borderRadius: "1px",
                            backgroundColor: "gray.800",
                            zIndex: -1,
                        },
                    })}
                >
                    {stepToParticipants[index]?.reverse().map((participant) => {
                        return (
                            <Flex key={participant.id}>
                                <Tooltip
                                    width="300px"
                                    borderRadius="6px"
                                    bg="gray.800"
                                    label={
                                        <ProductTourTooltip
                                            videoUrl={ProgressTrackerVideo}
                                            header="Progress tracker"
                                            description={
                                                PRODUCT_TOUR_TOOLTIPS[
                                                    ProductTourStep
                                                        .ProgressTracker
                                                ]
                                            }
                                        />
                                    }
                                    hasArrow
                                    placement="top-start"
                                    offset={[-24, 24]}
                                    isOpen={
                                        participant.id ===
                                            self?.sessionParticipantUuid &&
                                        isProductTour &&
                                        productTourStep ===
                                            ProductTourStep.ProgressTracker
                                    }
                                >
                                    <Avatar
                                        ref={
                                            participant.id ===
                                            self?.sessionParticipantUuid
                                                ? gatherPromptAnchorRef
                                                : null
                                        }
                                        size="2xs"
                                        width="20px"
                                        height="20px"
                                        key={participant.user.id}
                                        name={
                                            participant.user.firstName +
                                            " " +
                                            participant.user.lastName
                                        }
                                        src={participant.user.avatarUrl || ""}
                                        bg={
                                            PARTICIPANT_BACKGROUNDS[
                                                participant.index
                                            ]
                                        }
                                        borderColor="gray.800"
                                        borderWidth="2px"
                                    />
                                </Tooltip>
                            </Flex>
                        );
                    })}
                </AvatarGroup>
            </Tooltip>
        </Box>
    );
};
