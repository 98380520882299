import * as Sentry from "@sentry/react";
import React, { createContext, PropsWithChildren, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { debounce } from "../../../utils";
import {
    audioPermissionState,
    currentAudioInputDeviceId,
    currentAudioOutputDeviceId,
    currentVideoInputDeviceId,
    localDevicesState,
    videoPermissionState,
} from "../../immersionState";
import { getVideoDevices } from "../utils";
import { ChimeModalsProvider } from "./ChimeModals";
import { LocalAudioContextProvider } from "./LocalAudioContext";
import { LocalBlurContextProvider } from "./LocalBlurContext";
import { LocalNoiseCancellingContextProvider } from "./LocalNoiseCancellingContext";
import { LocalVideoContextProvider } from "./LocalVideoContext";
import { MeetingEventsContextProvider } from "./MeetingEventsContext";

export interface ChimeContext {
    /**
     * Scans local devices and attempts to ask for permission.
     *
     * If no permissions were granted, it will throw an error.
     */
    requestDevicePermissions: () => Promise<void>;

    refreshDevices: () => Promise<void>;
}

export const ChimeContext = createContext<ChimeContext>(
    null as unknown as ChimeContext,
);

export const ChimeContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const [devices, setDevices] = useRecoilState(localDevicesState);
    const [audioInputDevice, setAudioInputDevice] = useRecoilState(
        currentAudioInputDeviceId,
    );
    const [audioOutputDevice, setAudioOutputDevice] = useRecoilState(
        currentAudioOutputDeviceId,
    );
    const [videoDevice, setVideoDevice] = useRecoilState(
        currentVideoInputDeviceId,
    );

    const setVideoPermissionState = useSetRecoilState(videoPermissionState);
    const setAudioPermissionState = useSetRecoilState(audioPermissionState);

    const checkPermissions = async (type: "camera" | "microphone") => {
        const data = await navigator.permissions.query({
            name: type as PermissionName,
        });
        if (type === "camera") {
            setVideoPermissionState(data.state);
        } else {
            setAudioPermissionState(data.state);
        }
    };

    useEffect(() => {
        (async () => {
            checkPermissions("camera");
        })();
    }, []);

    useEffect(() => {
        (async () => {
            checkPermissions("microphone");
        })();
    }, []);

    /**
     * Initialize devices whenever new devices are listed
     */
    useEffect(() => {
        if (!audioInputDevice) {
            const audioInputDevice =
                devices.find((device) => device.kind === "audioinput")
                    ?.deviceId || null;
            setAudioInputDevice(audioInputDevice);
            if (audioInputDevice) {
                console.log(
                    `[Chime]: Selected audio input device: ${audioInputDevice}`,
                );
            }
        }
        if (!audioOutputDevice) {
            const audioOutputDevice =
                devices.find((device) => device.kind === "audiooutput")
                    ?.deviceId || null;
            setAudioOutputDevice(audioOutputDevice);
            if (audioOutputDevice) {
                console.log(
                    `[Chime]: Selected audio output device: ${audioOutputDevice}`,
                );
            }
        }
        if (
            !videoDevice ||
            !getVideoDevices(devices).find((_) => _.deviceId === videoDevice)
        ) {
            const videoDevice = getVideoDevices(devices)[0]?.deviceId || null;
            setVideoDevice(videoDevice);
            if (videoDevice) {
                console.log(`[Chime]: Selected video device: ${videoDevice}`);
            }
        }
    }, [devices, audioInputDevice, audioOutputDevice, videoDevice]);

    const onChangeDevice = debounce(async () => {
        console.log(`[Chime]: Enumerating devices`);
        // Check the new device name
        navigator.mediaDevices.enumerateDevices().then((deviceList) => {
            Sentry.captureMessage(
                "Devices changed: " + deviceList.map((d) => d.label).join(", "),
            );
            const devices = deviceList.filter((_) => _.label !== "");
            setDevices(devices);
            console.log(`[Chime]: Set ${devices.length} devices`);
        });
    }, 100);

    useEffect(() => {
        navigator.mediaDevices.addEventListener("devicechange", onChangeDevice);
        onChangeDevice();
        return () => {
            navigator.mediaDevices.removeEventListener(
                "devicechange",
                onChangeDevice,
            );
        };
    }, []);

    const context: ChimeContext = {
        refreshDevices: async () => onChangeDevice(),
        requestDevicePermissions: async () => {
            try {
                await navigator.mediaDevices.getUserMedia({
                    video: true,
                    audio: true,
                });
            } catch (e) {
                const error = e as Error;
                // Some of the devices are missing, could be audio or video
                if (error.name === "NotFoundError") {
                    // Audio is mandatory, so ask for permission
                    await navigator.mediaDevices.getUserMedia({
                        audio: true,
                    });
                } else {
                    throw e;
                }
            } finally {
                await checkPermissions("camera");
                await checkPermissions("microphone");
            }
            const devices = (
                await navigator.mediaDevices.enumerateDevices()
            ).filter((_) => _.label !== "");
            setDevices(devices);
        },
    };
    return (
        <ChimeContext.Provider value={context}>
            <LocalBlurContextProvider>
                <LocalNoiseCancellingContextProvider>
                    <LocalVideoContextProvider>
                        <LocalAudioContextProvider>
                            <MeetingEventsContextProvider>
                                <ChimeModalsProvider>
                                    {children}
                                </ChimeModalsProvider>
                            </MeetingEventsContextProvider>
                        </LocalAudioContextProvider>
                    </LocalVideoContextProvider>
                </LocalNoiseCancellingContextProvider>
            </LocalBlurContextProvider>
        </ChimeContext.Provider>
    );
};
