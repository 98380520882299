import React from "react";
import { trpc } from "./useTRPC";

export const useServerTimeOffset = () => {
    const { mutateAsync } = trpc.util.time.useMutation();
    const [offset, setOffset] = React.useState<number | null>(null);

    React.useEffect(() => {
        const clientTime = new Date().valueOf();
        mutateAsync().then((data) => {
            const clientTime2 = new Date().valueOf();
            const offset = data - clientTime / 2 - clientTime2 / 2;
            setOffset(offset);
        });
    }, []);

    return offset;
};
