import { trpc } from "./useTRPC";
import { Loading } from "../Loading";
import { useAccount } from "./useAccount";
import { chatwootVisible } from "../appState";
import { useEffect } from "react";
import { AccountNotFound } from "../home/AccountNotFound";
import { useSetAtom } from "jotai";

export const useAppReady = () => {
    const user = useAccount();

    const setChatwootVisible = useSetAtom(chatwootVisible);

    useEffect(() => {
        setChatwootVisible(true);
    }, []);

    if (user.isLoading) {
        return <Loading></Loading>;
    } else if (user.error) {
        return <div>Error...</div>;
    } else if (!user.data) {
        return <AccountNotFound />;
    }

    return null;
};

export const useIsMaintenanceWindow = () => {
    const { data } = trpc.account.getAppFlags.useQuery();
    return (
        data?.find((flag) => flag.name === "Maintenance window")?.enabled ??
        false
    );
};
