import {
    useParticipants as useSessionParticipants,
    useSelfData,
} from "../hooks/useImmersion";
import { PARTICIPANT_BACKGROUNDS_LIGHT } from "../../Constants";
import React from "react";
import { Box, BoxProps, Flex, Image, Text } from "@chakra-ui/react";
import BlankAvatarGrey from "design/assets/BlankAvatarGrey.svg";

export const ParticipantBox: React.FC<BoxProps> = (props) => {
    const sessionParticipants = useSessionParticipants();
    const self = useSelfData();
    const participant = sessionParticipants.find(
        (_) => _.id === self.sessionParticipantUuid,
    );
    if (!participant) {
        return <Image w="40%" src={BlankAvatarGrey} />;
    }
    if (participant.user.avatarUrl) {
        return (
            <Box w={"40%"} borderRadius={"100%"} overflow={"hidden"} {...props}>
                <Image
                    w="100%"
                    src={participant.user.avatarUrl}
                    alt={`Image of the participant's avatar`}
                />
            </Box>
        );
    }
    const initials = `${(participant.user.firstName || "")[0]}${(participant.user.lastName || "")[0]}`;
    const bg = PARTICIPANT_BACKGROUNDS_LIGHT[participant.index];
    return (
        <Flex
            w={"100%"}
            h={"100%"}
            bg={bg}
            fontSize="6xl"
            alignItems={"center"}
            alignContent={"center"}
            justifyContent={"center"}
            {...props}
        >
            <Text>{initials}</Text>
        </Flex>
    );
};
