import { useAtomValue } from "jotai";
import { localDevicesState } from "../../immersionState";
import { getOutputAudioDevices } from "../utils";

export const useOutputAudioDevices = () => {
    try {
        const devices = useAtomValue(localDevicesState);
        if ("setSinkId" in HTMLAudioElement.prototype) {
            return getOutputAudioDevices(devices);
        }
        return null;
    } catch (e) {
        return null;
    }
};
