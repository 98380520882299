import { DateTime } from "luxon";
import { useImmersion } from "./useImmersion";
import React from "react";
import { useServerTimeOffset } from "../../hooks/useServerTimeOffset";

/**
 * A hook that returns the remaining seconds of the immersion.
 */
export const useImmersionRemainingSeconds = (): number | null => {
    const offset = useServerTimeOffset() || 0;
    const immersion = useImmersion();
    const [remainingSeconds, setRemainingSeconds] = React.useState<
        number | null
    >(null);

    const immersionStartTime = immersion?.data?.time
        ? DateTime.fromISO(immersion.data.time)
        : null;
    const immersionDuration = immersion?.data?.immersion.length || 60;
    const immersionEndTime = immersionStartTime
        ? immersionStartTime.plus({
              minutes: immersionDuration,
          })
        : null;

    React.useEffect(() => {
        const interval = setInterval(() => {
            const now = DateTime.now().plus({
                milliseconds: offset,
            });
            if (
                immersionStartTime &&
                immersionEndTime &&
                now >= immersionStartTime &&
                now <= immersionEndTime
            ) {
                setRemainingSeconds(
                    Math.round(
                        (immersionEndTime.toJSDate().valueOf() -
                            now.toJSDate().valueOf()) /
                            1000,
                    ),
                );
            } else if (
                immersionStartTime &&
                immersionEndTime &&
                now >= immersionStartTime &&
                now > immersionEndTime &&
                now.toMillis() - immersionEndTime.toMillis() < 1000
            ) {
                setRemainingSeconds(0);
            } else {
                setRemainingSeconds(null);
            }
        }, 250);
        return () => clearInterval(interval);
    }, [immersionStartTime, immersionEndTime]);

    return remainingSeconds;
};
