import { ExitModal } from "./ExitModal";
import { ReactComponent as Rocket } from "design/assets/Rocket.svg";
import { Button, Text } from "@chakra-ui/react";
import { useSelfData } from "../hooks/useImmersion";

export const SuccessfulCompletionModal: React.FC<{
    show: boolean;
    onStay: () => void;
    onLeave: () => void;
}> = ({ show, onStay, onLeave }) => {
    const self = useSelfData();
    return (
        <ExitModal
            icon={<Rocket width={"92px"} height={"92px"} />}
            show={show}
            title={`Well done, ${self.firstName}!`}
            label={
                <Text color="gray.500" fontSize={"16px"} fontWeight={400}>
                    Your recap is ready for review.
                </Text>
            }
            onStay={onStay}
            exitButton={
                <Button colorScheme="blue" onClick={onLeave}>
                    Exit to Recap
                </Button>
            }
            id={"successful-completion-modal"}
        />
    );
};
