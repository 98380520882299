import { useSetAtom } from "jotai";
import { useParams } from "react-router-dom";
import { getAppStage } from "../../env";
import { useAccount, useParticipantType } from "../../hooks/useAccount";
import {
    useIsCatchUp,
    useIsFinalSession,
    useMementoReflectionQuestion,
    useMementoRemoveKeepChange,
    useMementoSkillsQuestion,
    useSelfData,
} from "../hooks/useImmersion";
import { newTabHelpUrl } from "../immersionState";
import { MementoResource } from "./resources/MementoResource";

export const Memento = () => {
    const { data: account } = useAccount();
    const self = useSelfData();
    const { id } = useParams();
    const setNewTabHelpUrl = useSetAtom(newTabHelpUrl);

    const appStage = getAppStage();

    const baseUrl = (() => {
        switch (appStage) {
            case "production":
                return "https://form.jotform.com/230791327111044";
            default:
                return "https://form.jotform.com/240650581352149";
        }
    })();

    const sessionParticipantRecordId = self?.sessionParticipantUuid;
    const fullName = `${account?.firstName} ${account?.lastName}`;
    const email = account?.emails?.[0]?.email ?? "";
    const participantType = useParticipantType();
    const organizationName = account?.organization?.name;
    const isFinalSession = useIsFinalSession() ? "Yes" : "No";
    const isCatchUp = useIsCatchUp() ? "Yes" : "No";
    const useReflectionQuestion = useMementoReflectionQuestion() ? "Yes" : "No";
    const useSkillsQuestion = useMementoSkillsQuestion() ? "Yes" : "No";
    const removeKeepChange = useMementoRemoveKeepChange() ? "Yes" : "No";
    const hostname = window.location.hostname;

    const url = `${baseUrl}?SessionParticipantRecordID=${encodeURIComponent(
        sessionParticipantRecordId,
    )}&Name=${encodeURIComponent(fullName)}&EmailAddress=${encodeURIComponent(
        email,
    )}&participantType=${encodeURIComponent(
        participantType,
    )}&organizationName=${organizationName}&finalSession=${isFinalSession}&catchUp=${isCatchUp}&host=${encodeURIComponent(
        hostname,
    )}&timeslotId=${id}&reflectionQuestion=${useReflectionQuestion}&skillsQuestion=${useSkillsQuestion}&removeKeepChange=${removeKeepChange}`;

    setNewTabHelpUrl(url);

    return <MementoResource url={url} name={"memento"} />;
};
