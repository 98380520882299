import {
    Box,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuDivider,
    MenuList,
    Text,
    Tooltip,
    useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon, SpeakerWaveIcon } from "@heroicons/react/24/outline";
import audio from "design/assets/sampleSound1.mp3";
import { useAtomValue } from "jotai";
import React from "react";
import AudioErrorIcon from "../../../assets/audio_error.svg";
import {
    audioPermissionState,
    currentAudioInputDeviceId,
    isAudioEnabledState,
    localDevicesState,
} from "../../immersionState";
import { MicrophoneDeviceListMenu } from "./MicrophoneDeviceListMenu";
import { MicrophoneLevelMenu } from "./MicrophoneLevelMenu";
import { SpeakerSelectorMenu } from "./SpeakerSelectorMenu";
import { TestSoundMenu } from "./TestSoundMenu";

export const AudioMenu: React.FC<{
    audioLevel: number;
    suppressAudioErrorMessage?: boolean;
}> = ({ audioLevel, suppressAudioErrorMessage = false }) => {
    const {
        isOpen: isAudioMenuOpen,
        onOpen: onAudioMenuOpen,
        onClose: onAudioMenuClose,
    } = useDisclosure();

    const devices = useAtomValue(localDevicesState);
    const audioInputDevice = useAtomValue(currentAudioInputDeviceId);
    const isAudioEnabled = useAtomValue(isAudioEnabledState);

    const [tooltipView, setTooltipView] = React.useState(false);

    const [testSoundPlaying, setTestSoundPlaying] = React.useState(false);
    const testSound = React.useMemo(() => new Audio(audio), []);
    const audioPermission = useAtomValue(audioPermissionState);

    const shouldShowTooltip = audioPermission !== "denied";
    const shouldOpenTooltip =
        !suppressAudioErrorMessage &&
        shouldShowTooltip &&
        !isAudioMenuOpen &&
        tooltipView &&
        !!audioInputDevice &&
        isAudioEnabled;

    // useEffect for hide and show tooltip
    React.useEffect(() => {
        if (audioLevel <= 0) {
            const timeoutId = setTimeout(() => {
                setTooltipView(true);
            }, 1500);
            return () => clearTimeout(timeoutId);
        } else {
            setTooltipView(false);
        }
    }, [audioLevel]);

    React.useEffect(() => {
        const playListener = () => {
            setTestSoundPlaying(true);
        };
        const pauseListener = () => {
            setTestSoundPlaying(false);
        };
        testSound.addEventListener("play", playListener);
        testSound.addEventListener("pause", pauseListener);

        return () => {
            testSound.pause();
            testSound.remove();
            testSound.removeEventListener("play", playListener);
            testSound.removeEventListener("pause", pauseListener);
        };
    }, [testSound]);

    return (
        <Flex flexDirection="column" gap="5px" width="49%">
            <Flex gap="2px">
                <Text fontSize="sm" fontWeight="semibold">
                    Audio
                </Text>
                <Tooltip
                    isOpen={shouldOpenTooltip}
                    hasArrow
                    placement="top"
                    label={
                        "No signal detected from selected microphone. Change your audio input device or refresh this page."
                    }
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="17px"
                    textAlign="left"
                    borderRadius={"4px"}
                    bg="gray.800"
                    color="gray.100"
                    padding="8px"
                    w="206px"
                >
                    <Image
                        src={AudioErrorIcon}
                        display={
                            shouldShowTooltip && tooltipView && isAudioEnabled
                                ? "block"
                                : "none"
                        }
                        w="16px"
                        h="16px"
                        alt={"Audio signal error indicator"}
                    />
                </Tooltip>
            </Flex>

            <Menu
                isOpen={isAudioMenuOpen}
                onClose={onAudioMenuClose}
                onOpen={onAudioMenuOpen}
            >
                <MenuButton
                    borderWidth="1px"
                    borderColor="gray.100"
                    borderRadius="6px"
                    disabled={audioPermission === "denied"}
                    bg={audioPermission === "denied" ? "gray.25" : undefined}
                    height={"36px"}
                >
                    <Flex
                        height={"34px"}
                        padding="6px 10px 6px 8px"
                        justifyContent="space-between"
                        gap="12px"
                    >
                        <Flex alignItems="center" gap="8px" overflow="hidden">
                            {testSoundPlaying && (
                                <SpeakerWaveIcon height="16px" />
                            )}
                            <Text
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                textAlign="left"
                                fontSize="14px"
                                lineHeight="22px"
                                color="gray.700"
                            >
                                {testSoundPlaying
                                    ? "Playing..."
                                    : devices?.filter(
                                          (device) =>
                                              device.deviceId ===
                                              audioInputDevice,
                                      )?.[0]?.label}
                            </Text>
                        </Flex>
                        <Box alignSelf="center">
                            <ChevronDownIcon height="16px" color="gray.700" />
                        </Box>
                    </Flex>
                </MenuButton>
                <MenuList
                    minWidth="240px"
                    zIndex="2402"
                    maxH="410px"
                    overflowY="auto"
                >
                    <MicrophoneDeviceListMenu />
                    <MenuDivider />
                    <MicrophoneLevelMenu audioLevel={audioLevel} />
                    <SpeakerSelectorMenu />
                    <MenuDivider />
                    <TestSoundMenu
                        testSound={testSound}
                        testSoundPlaying={testSoundPlaying}
                    />
                </MenuList>
            </Menu>
        </Flex>
    );
};
