import {
    Button,
    Flex,
    HStack,
    Icon,
    Image,
    keyframes,
    Link,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { EyeIcon, EyeSlashIcon, PlayIcon } from "@heroicons/react/24/outline";
import GraphPaper from "design/assets/GraphPaper.png";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import { PRODUCT_TOUR_TOOLTIPS } from "../../Constants";
import { useIsLiveOnboarding } from "../hooks/useImmersion";
import { Hotspot } from "../Hotspot";
import { IMMERSION_SIZES } from "../Immersion";
import {
    ProductTourStep,
    productTourStep as productTourStepState,
} from "../immersionState";
import { ProductTourTooltip } from "../ProductTourTooltip";
import { ResourceHeader } from "./resources/ResourceHeader";

const scaleIn = keyframes`
0% {
    transform: scale(0);
}
100% {
    transform: scale(1);
}
`;

const appear = keyframes`
0% {
    visibility: visible;
    transform: scale(0.8);
}
100% {
    visibility: visible;
    transform: scale(1);
}
`;

const hideRevealStyles = keyframes`
100% {
    box-shadow: none;
    border-color: white;
}
`;

export const ProductTourWorkspace = () => {
    const [productTourStep, setProductTourStep] = useAtom(productTourStepState);
    const {
        STEP_MARGIN_TOP,
        STEP_MARGIN_BOTTOM,
        HEADER_HEIGHT,
        FOOTER_HEIGHT,
    } = IMMERSION_SIZES;
    const workspaceAddedBorderAdjustment = "2px";
    const maxH = `calc(100vh - ${STEP_MARGIN_TOP} - ${STEP_MARGIN_BOTTOM} - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT} - ${workspaceAddedBorderAdjustment})`;
    const showHotspot = productTourStep === ProductTourStep.Intro;
    const showWorkspace = productTourStep >= ProductTourStep.Workspace;
    const isLiveOnboarding = useIsLiveOnboarding();
    const [showingTranscript, setShowingTranscript] = useState(false);
    const [displayContinue, setDisplayContinue] = useState(false);
    const videoContainer = useRef<HTMLDivElement>(null);
    const [videoPlayed, setVideoPlayed] = useState(false);

    // instruction card
    const heading = "Click the orange dots to learn the interface!";
    const cardStyles = showWorkspace
        ? {
              bgColor: "info.-4",
              boxShadow: "none",
              borderWidth: "12px",
              borderStyle: "solid",
              borderColor: "info.-4",
          }
        : {
              bgColor: "blue.background",
              boxShadow: "0px 9px 30px 0px rgba(0, 0, 0, 0.12)",
              borderWidth: "12px",
              borderStyle: "solid",
              borderColor: "white",
          };

    // On screen resize, size the video container based on the container size
    // while keeping the video aspect ratio maintained at 16/9
    // and not exceeding the container size in a way that leaves a scroll bar
    useEffect(() => {
        const resizeVideoContainer = () => {
            const videoContainerElement = videoContainer.current;
            if (!videoContainerElement) return;

            const videoContainerWidth =
                videoContainerElement.parentElement?.clientWidth || 0;
            const videoContainerHeight =
                videoContainerElement.parentElement?.clientHeight || 0;
            const videoContainerAspectRatio =
                videoContainerWidth / videoContainerHeight;
            const videoAspectRatio = 16 / 9;
            videoContainerElement.style.height = "100%";
            videoContainerElement.style.width = "100%";

            if (videoContainerAspectRatio > videoAspectRatio) {
                // Manually set the width to keep the aspect ratio correct
                const computedWidth = Math.min(
                    videoContainerElement.clientHeight * videoAspectRatio,
                    videoContainerElement.parentElement?.clientWidth || 0,
                );
                videoContainerElement.style.width = `${computedWidth}px`;
                const computedHeight = computedWidth / videoAspectRatio;
                videoContainerElement.style.height = `${computedHeight}px`;
            } else {
                // Manually set the height to keep the aspect ratio correct
                const computedHeight = Math.min(
                    videoContainerElement.clientWidth / videoAspectRatio,
                    (videoContainerElement.parentElement?.clientHeight || 0) -
                        8,
                );
                videoContainerElement.style.height = `${computedHeight}px`;
                const computedWidth = computedHeight * videoAspectRatio;
                videoContainerElement.style.width = `${computedWidth}px`;
            }
        };
        resizeVideoContainer();
        window.addEventListener("resize", resizeVideoContainer);
        return () => {
            window.removeEventListener("resize", resizeVideoContainer);
        };
    }, [videoContainer, productTourStep, showingTranscript]);

    // always start product tour from beginning
    useEffect(() => {
        if (isLiveOnboarding) {
            setProductTourStep(ProductTourStep.Intro);
        } else {
            setProductTourStep(ProductTourStep.OrientationVideo);
        }
    }, []);

    if (productTourStep === ProductTourStep.OrientationVideo) {
        return (
            <Flex w="100%" justifyContent="center" height="fit-content">
                <Flex
                    maxH={maxH}
                    overflowY={"auto"}
                    border="12px solid white"
                    borderRadius="40px"
                    backgroundColor="gray.25"
                    w="620px"
                    boxShadow="0px 16px 24px -4px #06142D14"
                    padding="20px 20px 20px 20px"
                    alignItems="center"
                    justifyItems="center"
                    flexDir="column"
                >
                    <Flex
                        marginBottom="24px"
                        gap="12px"
                        flexDir="column"
                        alignItems="center"
                    >
                        <Text fontWeight="600" fontSize="28px" color="dark.800">
                            What to expect
                        </Text>
                        <Text fontWeight="400" fontSize="14px" color="dark.600">
                            Start by getting in the right mindset
                        </Text>
                    </Flex>
                    <Flex
                        marginBottom="8px"
                        width="100%"
                        alignSelf="center"
                        justifySelf="center"
                        borderRadius={20}
                        minW="300px"
                        minH="169px"
                        overflow="hidden"
                        position="relative"
                        ref={videoContainer}
                    >
                        <Flex
                            width="100%"
                            height="100%"
                            position="absolute"
                            zIndex={100}
                            display={videoPlayed ? "none" : "flex"}
                            cursor="pointer"
                            onClick={() => setVideoPlayed(true)}
                        >
                            <Button margin="auto" size="lg" gap="8px">
                                <PlayIcon height="16px" />
                                Watch this 30 sec video!
                            </Button>
                        </Flex>
                        {/* 
                    ReactPlayer renders a container div with a video element inside
                    The "width" and "height" props are used on the video element)
                    The "style" prop is used on the container div*/}
                        <ReactPlayer
                            url={`${process.env.REACT_APP_API_ROOT}/app/public/stream/01ab2e06615a2304305a1796c1f04954/manifest/video.mpd?defaultTextTrack=en`}
                            controls={true}
                            width="100%"
                            height="100%"
                            // the styles below make the player respect the size limitations
                            // of the Flex parent container and not flow outside the container
                            // to maintain the aspect ratio
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                top: "0",
                                left: "0",
                            }}
                            config={{
                                file: {
                                    attributes: {
                                        disablePictureInPicture: true,
                                        crossOrigin: "anonymous",
                                    },
                                    tracks: [
                                        {
                                            kind: "subtitles",
                                            label: "English",
                                            src: `${process.env.REACT_APP_API_ROOT}/app/public/stream/01ab2e06615a2304305a1796c1f04954/captions/en`,
                                            srcLang: "en",
                                            default: true,
                                        },
                                    ],
                                },
                            }}
                            onPause={() => setDisplayContinue(true)}
                            onEnded={() => setDisplayContinue(true)}
                            playing={videoPlayed}
                        />
                    </Flex>
                    <Flex
                        flex="1"
                        flexDirection={"row"}
                        justifyContent="space-between"
                        w="100%"
                    >
                        {displayContinue ? (
                            <Button
                                minHeight="40px"
                                colorScheme="black"
                                onClick={() =>
                                    setProductTourStep(ProductTourStep.Intro)
                                }
                            >
                                Continue
                            </Button>
                        ) : (
                            <Link
                                m={"8px"}
                                height="40px"
                                fontWeight="400"
                                fontSize="14px"
                                color="#787A80"
                                onClick={() =>
                                    setProductTourStep(ProductTourStep.Intro)
                                }
                            >
                                Skip intro video
                            </Link>
                        )}
                        <Button
                            size="md"
                            m={showingTranscript ? "8px" : "0px"}
                            bgColor="gray.background"
                            color="blue.500"
                            borderRadius="8px"
                            onClick={() =>
                                setShowingTranscript(!showingTranscript)
                            }
                            _hover={{
                                bg: "gray.100",
                            }}
                        >
                            <HStack>
                                <Icon
                                    as={
                                        showingTranscript
                                            ? EyeSlashIcon
                                            : EyeIcon
                                    }
                                />
                                <Text fontFamily="Inter" fontSize={"14px"}>
                                    {showingTranscript
                                        ? "Hide Transcript"
                                        : "Show Transcript"}
                                </Text>
                            </HStack>
                        </Button>
                    </Flex>
                </Flex>
                {showingTranscript && (
                    <Flex
                        maxW="400px"
                        marginBottom="24px"
                        direction="column"
                        mt="8px"
                        borderRadius="12px"
                        bgColor={showingTranscript ? "gray.background" : "none"}
                        margin="auto"
                    >
                        <Flex minH={12} pb="16px" justifyContent="center">
                            <Flex
                                overflowY="auto"
                                p="0px 16px"
                                minW="300px"
                                maxW="700px"
                            >
                                <ReactMarkdown className="exclude-from-reset richtext">
                                    {`Welcome. You are about to embark on a group learning experience. You will get connected with a small group to solve problems, get hands on practice, exchange feedback with one another, and share your reflections.

If you have done other online learning, this will feel quite different. It will be active, social, and empowering. You and your group are in charge, responsible for shaping the experience together. There will be prompts, a timer, and structured activities to guide you.

To make the most of it, approach it openly, like a trip to a new place. Have fun!`}
                                </ReactMarkdown>
                            </Flex>
                        </Flex>
                    </Flex>
                )}
            </Flex>
        );
    }

    return (
        <Flex flex="1" direction="column" align="center">
            {/* Workspace */}
            {showWorkspace && (
                <Flex
                    w="100%"
                    h="100%"
                    maxH={maxH}
                    alignContent="center"
                    borderWidth="1px"
                    borderRadius="12px"
                    borderColor="warning.-3"
                    boxShadow="0px 0px 12px 0px #F17437;"
                    transformOrigin="center"
                    animation={`${scaleIn} 0.5s, ${hideRevealStyles} 0.5s linear forwards 3s`}
                >
                    <Flex flex="1" justifyContent="center">
                        <Flex
                            flex="1"
                            direction="column"
                            borderWidth="1px"
                            borderColor="gray.100"
                            borderRadius="12px"
                            overflow="hidden"
                            maxH={maxH}
                        >
                            <ResourceHeader
                                headerType="product tour"
                                resourceType="image"
                            />
                            <Image
                                height="100%"
                                objectFit="cover"
                                src={GraphPaper}
                                alt="Workspace"
                            />
                        </Flex>
                    </Flex>
                </Flex>
            )}
            <Flex
                direction="column"
                h="100%"
                w="100%"
                align="center"
                justify="center"
                position="absolute"
            >
                {/* Instruction flashcard */}
                <Flex
                    direction="column"
                    w="400px"
                    mb="32px"
                    borderRadius="20px"
                    p="48px 48px 40px 48px"
                    {...cardStyles}
                >
                    <Text
                        textAlign="center"
                        fontFamily="Inter"
                        fontWeight="600"
                        fontSize="24px"
                        lineHeight="29px"
                        color={showWorkspace ? "dark.700" : "dark.800"}
                    >
                        {heading}
                    </Text>
                </Flex>
                {/* Hotspot button */}
                <Tooltip
                    hasArrow
                    placement="bottom"
                    borderRadius="6px"
                    bg="gray.800"
                    label={
                        <ProductTourTooltip
                            header="Workspace"
                            description={
                                PRODUCT_TOUR_TOOLTIPS[ProductTourStep.Workspace]
                            }
                        />
                    }
                    isOpen={productTourStep === ProductTourStep.Workspace}
                    // the openDelay prop seems to have buggy behavior when used with the isOpen prop
                    // so the following is a workaround:
                    visibility="hidden"
                    animation={`${appear} 0.1s forwards 0.5s`}
                >
                    <Flex justify="center" align="center" h="24px" w="24px">
                        {showHotspot && (
                            <Hotspot
                                onClick={() => {
                                    setProductTourStep(
                                        ProductTourStep.Workspace,
                                    );
                                }}
                                delay={0.5}
                            />
                        )}
                    </Flex>
                </Tooltip>
            </Flex>
        </Flex>
    );
};
