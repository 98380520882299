import { Icon, MenuItem, MenuOptionGroup, Progress } from "@chakra-ui/react";
import { MicrophoneIcon } from "@heroicons/react/24/outline";
import { ReactComponent as MicButtonOff } from "design/assets/MicButtonOff.svg";
import { useAtomValue } from "jotai";
import React from "react";
import { isAudioEnabledState } from "../../immersionState";
import { menuStyles } from "../JoinVideoModal";

export const MicrophoneLevelMenu: React.FC<{
    audioLevel: number;
}> = ({ audioLevel }) => {
    const isAudioEnabled = useAtomValue(isAudioEnabledState);
    return (
        <MenuOptionGroup
            title="Microphone Level"
            {...menuStyles.menuOptionGroup}
        >
            <MenuItem
                icon={
                    isAudioEnabled ? (
                        <MicrophoneIcon height="16px" />
                    ) : (
                        <Icon as={MicButtonOff} fontSize="16px" />
                    )
                }
                _hover={{
                    background: "white",
                    cursor: "default",
                }}
                isFocusable={false}
                {...menuStyles.menuItem}
                pr="16px"
            >
                <Progress
                    opacity=".75"
                    rounded="10px"
                    alignSelf={"center"}
                    colorScheme={"positive"}
                    value={audioLevel}
                    max={10}
                    size="sm"
                    display={"inline-block"}
                    width="100%"
                />
            </MenuItem>
        </MenuOptionGroup>
    );
};
