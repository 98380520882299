import * as Sentry from "@sentry/react";
import { useParams } from "react-router-dom";
import { trpc } from "../../hooks/useTRPC";
import { meetingSession } from "../immersionState";
import {
    useHasVisitedGroupSession,
    useIsCatchUp,
    useIsSoloSession,
    useSelfData,
    useSessionParticipantAsLoader,
} from "./useImmersion";
import { useAtomValue } from "jotai";

/**
 *
 * @returns all content for the current session
 */
export const useSessionContent = () => {
    const { id, groupId } = useParams();
    if (id) {
        const { data, error } = trpc.immersion.getContentForSession.useQuery({
            sessionId: id,
            groupId,
        });
        if (error) {
            Sentry.captureException(error);
        }
        return data;
    } else {
        return null;
    }
};

export const useSessionContentAsLoader = () => {
    const { id } = useParams();
    if (id) {
        const sessionContentLoader =
            trpc.immersion.getContentForSession.useQuery({
                sessionId: id,
            });
        if (sessionContentLoader.error) {
            Sentry.captureException(sessionContentLoader.error);
        }
        return sessionContentLoader;
    } else {
        return null;
    }
};

/**
 *
 * factorRoom - whether to consider whether the user is already connected to the room.
 *
 * @returns whether the join video button should show.
 */
export const useShouldJoinVideo = (factorRoom: boolean) => {
    const isCatchUp = useIsCatchUp();
    const isSoloSession = useIsSoloSession();
    const chimeSession = useAtomValue(meetingSession);
    const isInRoom = !!chimeSession;
    const sessionContent = useSessionContent();
    const stepNumber = parseInt(
        useParams<{ stepNumber: string }>().stepNumber || "0",
    );
    const joinVideoStepNumber = useJoinVideoStepNumber();
    const leaveVideoStepNumber = useLeaveVideoStepNumber();

    if (isCatchUp || isSoloSession) {
        return false;
    }
    if ((factorRoom && isInRoom) || !sessionContent) {
        return false;
    }

    if (leaveVideoStepNumber && stepNumber >= leaveVideoStepNumber) {
        return false;
    } else if (joinVideoStepNumber && stepNumber >= joinVideoStepNumber) {
        return true;
    } else {
        return false;
    }
};

/**
 *
 * @returns whether the user should be prompted to join video after step
 */
export const useShouldJoinVideoAfterStep = () => {
    const isCatchUp = useIsCatchUp();
    const isSoloSession = useIsSoloSession();
    const chimeSession = useAtomValue(meetingSession);
    const isInRoom = !!chimeSession;
    const sessionContent = useSessionContent();
    const stepNumber = parseInt(
        useParams<{ stepNumber: string }>().stepNumber || "0",
    );
    if (isInRoom || !sessionContent || isCatchUp || isSoloSession) {
        return false;
    }

    return sessionContent[stepNumber].joinVideoCta;
};

/**
 * @returns the step number of the join video step, without relying on
 * the url params. (Needed for matching screen which has no step number).
 */
export const useJoinVideoStepNumber = () => {
    const sessionContent = useSessionContent();
    if (!sessionContent) {
        return null;
    }
    for (let index = 0; index < sessionContent.length; index++) {
        if (sessionContent[index].joinVideoCta) {
            return index + 1;
        }
    }
    return null;
};

/**
 *
 * @returns whether the user should be prompted to leave video after step
 */
export const useShouldLeaveVideoAfterStep = () => {
    const isCatchUp = useIsCatchUp();
    const isSoloSession = useIsSoloSession();
    const chimeSession = useAtomValue(meetingSession);
    const isInRoom = !!chimeSession;

    const sessionContent = useSessionContent();
    const stepNumber = parseInt(
        useParams<{ stepNumber: string }>().stepNumber || "0",
    );

    // note that we do not want to show "leave group" button if user is not currently connected to room
    if (!isInRoom || !sessionContent || isCatchUp || isSoloSession) {
        return false;
    }

    return sessionContent[stepNumber].leaveVideoCta;
};

/**
 * @returns the step number of the leave video step, without relying on
 * the url params.
 */
export const useLeaveVideoStepNumber = () => {
    const sessionContent = useSessionContent();
    if (!sessionContent) {
        return null;
    }
    for (let index = 0; index < sessionContent.length; index++) {
        if (sessionContent[index].leaveVideoCta) {
            return index + 1;
        }
    }
    return null;
};

/**
 *
 * @returns the step type of the current step (ie: "Full-screen image")
 */
export const useStepType = () => {
    const sessionContent = useSessionContent();
    const stepNumber = parseInt(
        useParams<{ stepNumber: string }>().stepNumber || "0",
    );

    // A little hack to speed up loading of the first page.
    if (stepNumber === 0) {
        return "Immersion cover slide";
    }
    if (!sessionContent) {
        return null;
    }
    return sessionContent[stepNumber].stepTypeName;
};

/**
 *
 * @returns the step type of the next step (ie: "Full-screen image")
 */
export const useNextStepType = () => {
    const sessionContent = useSessionContent();
    const nextStepNumber =
        1 + parseInt(useParams<{ stepNumber: string }>().stepNumber || "0");

    if (!sessionContent) {
        return null;
    }
    if (nextStepNumber >= sessionContent.length) {
        return null;
    }
    return sessionContent[nextStepNumber].stepTypeName;
};

// Which step should clicking "next step" take you to?
export const useNextStepNumber = () => {
    const hasVisitedGroupSession = useHasVisitedGroupSession();
    const nextStepType = useNextStepType();
    const { stepNumber } = useParams();
    const isCatchUp = useIsCatchUp();
    let nextStepNumber: number = Number(stepNumber) + 1;
    // Skip the product tour step if this is a catch up session, OR if the user has already done a session in a group with more than 1 person.
    if (
        (isCatchUp || hasVisitedGroupSession) &&
        nextStepType === "Product tour"
    ) {
        nextStepNumber += 1;
    }
    return nextStepNumber;
};

export const useStepUrl = (stepNumber: number): string | null => {
    const { id } = useParams();
    const sessionParticipantLoader = useSessionParticipantAsLoader();
    if (sessionParticipantLoader?.isLoading) {
        return null;
    }
    const groupId = sessionParticipantLoader?.data?.groupId;
    if (groupId) {
        return `/immersion/${id}/group/${groupId}/${stepNumber}`;
    }
    return `/immersion/${id}/intro/${stepNumber}`;
};

/**
 *
 * @returns whether or not this step should show help in the sidebar
 */
export const useShouldShowHelp = () => {
    const stepType = useStepType();
    const helpSteps = [
        "Full-screen editable GSheet",
        "Memento form",
        "Nth participant GSheet (read-only)",
        "Video file + editable Etherpad",
        "Video file w/caption side",
    ];
    return helpSteps.includes(stepType || "");
};

/**
 *
 * @returns if the current step has a video portion.
 */
export const useIsStepVideo = () => {
    const stepType = useStepType();
    const videoSteps = [
        "Video file + editable Etherpad",
        "Video file w/caption side",
    ];
    return videoSteps.includes(stepType || "");
};

/**
 * Used for the help item shown in sidebar.
 * @returns if the current step has a GSheet portion.
 */
export const useIsStepGSheet = () => {
    const stepType = useStepType();
    const gsheetSteps = [
        "Full-screen editable GSheet",
        "Nth participant GSheet (read-only)",
    ];
    return gsheetSteps.includes(stepType || "");
};

/**
 * Used for the help item shown in sidebar.
 * @returns if the current step has a Memento portion.
 */
export const useIsStepMemento = () => {
    const stepType = useStepType();
    return stepType === "Memento form";
};

/**
 *
 * @returns if the current step is the product tour.
 */
export const useIsProductTour = () => {
    const stepType = useStepType();
    return stepType === "Product tour";
};

/**
 *
 * @returns if the current step is a pre-immersion step.
 */
export const useIsPreImmersion = () => {
    const stepType = useStepType();
    return stepType === "Immersion cover slide" || stepType === null;
};

/**
 *
 * @returns whether we are currently on the final step of the immersion.
 */
export const useIsFinalStep = () => {
    const sessionContent = useSessionContent();
    const stepNumber = parseInt(
        useParams<{ stepNumber: string }>().stepNumber || "0",
    );

    if (!sessionContent) {
        return false;
    }
    if (stepNumber === sessionContent.length - 1) {
        return true;
    }
    return false;
};

/**
 *
 * @returns the resources and variables for the current step, for all users
 */
export const useStepContent = () => {
    const sessionContent = useSessionContent();
    const stepNumber = parseInt(
        useParams<{ stepNumber: string }>().stepNumber || "0",
    );
    if (!sessionContent) {
        return {};
    }
    const step = sessionContent[stepNumber];
    const resources = step?.resources;
    const variables = step?.variables;
    const instructions = step?.instructions;
    const participation = step?.participation;
    const duration = step?.duration;
    const name = step?.stepTypeName;
    const menuTitle = step.menuTitle;

    return {
        resources,
        variables,
        instructions,
        participation,
        duration,
        name,
        menuTitle,
    };
};

export const useEtherpadCookie = () => {
    const sessionContent = useSessionContent();
    const sessionIds = [];
    for (let step of sessionContent || []) {
        for (let resourceSet of [
            step.resources?.resource1 || [],
            step.resources?.resource2 || [],
            step.resources?.resource3 || [],
        ]) {
            for (let resource of resourceSet) {
                if (resource?.sessionId) {
                    sessionIds.push(resource.sessionId);
                }
            }
        }
    }

    return sessionIds.join(",");
};

/**
 * @returns the resources and variables for the current step, only for the current user.
 */
export const useOwnResources = () => {
    const self = useSelfData();
    const { resources = {} } = useStepContent();

    let ownResource1, ownResource2, ownResource3;
    if (resources?.resource1) {
        ownResource1 = resources.resource1?.find(
            (resource) =>
                resource.sessionParticipantUuid ===
                    self?.sessionParticipantUuid ||
                !resource.sessionParticipantUuid,
        );
    }
    if (resources?.resource2) {
        ownResource2 = resources.resource2?.find(
            (resource) =>
                resource.sessionParticipantUuid ===
                    self?.sessionParticipantUuid ||
                !resource.sessionParticipantUuid,
        );
    }
    if (resources?.resource3) {
        ownResource3 = resources.resource3?.find(
            (resource) =>
                resource.sessionParticipantUuid ===
                    self?.sessionParticipantUuid ||
                !resource.sessionParticipantUuid,
        );
    }

    const ownResources = {
        ...(ownResource1 && { resource1: ownResource1 }),
        ...(ownResource2 && { resource2: ownResource2 }),
        ...(ownResource3 && { resource3: ownResource3 }),
    };
    return ownResources;
};
