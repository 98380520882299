import {
    Box,
    Button,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import LockUrlBar from "design/assets/LockUrlBar.png";
import React from "react";

export const MicrophonePermissionsModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minW={"600px"}>
                <ModalHeader>
                    <Heading
                        fontWeight="bold"
                        fontSize="22px"
                        textAlign="center"
                    >
                        Allow access to your microphone
                    </Heading>
                </ModalHeader>
                <ModalBody mt={"4px"}>
                    <Box
                        bg="blue.50"
                        borderRadius="20px"
                        padding="20px"
                        fontSize="14px"
                    >
                        <Grid
                            templateRows="repeat(3)"
                            templateColumns="repeat(8, 1fr)"
                            gap="20px 10px"
                        >
                            <GridItem>
                                <b>Step 1</b>
                            </GridItem>
                            <GridItem colSpan={7}>
                                Click the icon to the left of your browser's
                                address bar
                                <Image
                                    h="30px"
                                    marginTop="7px"
                                    src={LockUrlBar}
                                />
                            </GridItem>
                            <GridItem>
                                <b>Step 2</b>
                            </GridItem>
                            <GridItem colSpan={7}>Turn on microphone.</GridItem>
                        </Grid>
                    </Box>
                </ModalBody>
                <ModalFooter pb={0}>
                    <Flex
                        w={"100%"}
                        justifyContent={"center"}
                        direction={"row"}
                    >
                        <Button
                            onClick={onClose}
                            variant="outline"
                            colorScheme="gray"
                            background="white"
                            marginRight="10px"
                        >
                            Close
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
