import { EventAttributes, EventName } from "amazon-chime-sdk-js";
import { useAtomValue, useSetAtom } from "jotai";
import { usePostHog } from "posthog-js/react";
import React, {
    createContext,
    PropsWithChildren,
    useEffect,
    useRef,
    useState,
} from "react";
import { meetingEventState, meetingSession } from "../../immersionState";
import { meetingErrorHelpMessage } from "../ErrorLib";

export interface MeetingEventsContext {}

export const MeetingEventsContext = createContext<MeetingEventsContext>(
    null as unknown as MeetingEventsContext,
);

export const MeetingEventsContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const posthog = usePostHog();
    const setMeetingEvent = useSetAtom(meetingEventState);
    const [meetingIsReady, setMeetingIsReady] = useState<boolean>(false);
    const meetingReadyRef = useRef<boolean>(false);
    meetingReadyRef.current = meetingIsReady;

    const meetingManager = useAtomValue(meetingSession);
    const eventController = meetingManager?.eventController;

    const meetingEventUpdateCallback = (
        name: EventName,
        attributes: EventAttributes,
    ): void => {
        setMeetingEvent({ name, attributes });

        if (name === "meetingStartSucceeded") {
            setMeetingIsReady(true);
        }

        // Handle specific error events
        const errorMessage = meetingErrorHelpMessage(name, attributes);
        if (errorMessage) {
            console.error(
                `[Chime]: ${name} ${JSON.stringify({
                    ...attributes,
                    meetingHistory: undefined,
                })}`,
            );
        }

        posthog.capture(`chime_${name}`, {
            ...attributes,
            meetingHistory: undefined,
            // Ensure PostHog queries use the timestamp from when the event occurred according to Chime,
            // rather than the time when the event was captured here.
            // https://posthog.com/docs/data/timestamps
            timestamp: new Date(
                attributes.timestampMs || Date.now(),
            ).toISOString(),
            sent_at: new Date().toISOString(),
        });
    };

    useEffect(() => {
        if (!eventController) {
            return;
        }

        // Subscribe to events
        eventController.addObserver({
            eventDidReceive: meetingEventUpdateCallback,
        });

        // Cleanup function to unsubscribe from events
        return () => {
            eventController.removeObserver({
                eventDidReceive: meetingEventUpdateCallback,
            });
            setMeetingEvent(undefined);
        };
    }, [eventController]);
    return (
        <MeetingEventsContext.Provider value={{}}>
            {children}
        </MeetingEventsContext.Provider>
    );
};
