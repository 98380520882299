import React from "react";
import { menuStyles } from "../JoinVideoModal";
import { MenuItem } from "@chakra-ui/react";
import { SpeakerWaveIcon } from "@heroicons/react/24/outline";
import { currentAudioOutputDeviceId } from "../../immersionState";
import { useAtomValue } from "jotai";

export const TestSoundMenu: React.FC<{
    testSoundPlaying: boolean;
    testSound: HTMLAudioElement;
}> = ({ testSound, testSoundPlaying }) => {
    const audioOutputDevice = useAtomValue(currentAudioOutputDeviceId);
    return (
        <MenuItem
            icon={<SpeakerWaveIcon height="16px" />}
            onClick={() => {
                if (audioOutputDevice) {
                    testSound.setSinkId(audioOutputDevice);
                }
                testSound.play();
            }}
            {...menuStyles.menuItem}
        >
            {testSoundPlaying ? "Playing..." : "Play a test sound"}
        </MenuItem>
    );
};
