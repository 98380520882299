import {
    AspectRatio,
    Box,
    Flex,
    Image,
    Portal,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import BadInternet from "design/assets/BadInternet.svg";
import BlankAvatarGrey from "design/assets/BlankAvatarGrey.svg";
import MicOff from "design/assets/MicOff.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAttendeeAudioStatus } from "../hooks/useAttendeeAudioStatus";
import { useLocalAudio } from "../hooks/useLocalAudio";
import usePublications from "../hooks/usePublications";
import useRoster from "../hooks/useRoster";
import { Publication } from "./Publication";
import { useVideoDevices } from "../hooks/useVideoDevices";
import NoVideo from "design/assets/NoVideo.svg";

export const Participant = ({
    local,
    participantId,
    firstName,
    lastName,
    background,
    width,
    activeSpeakerAttendeeId,
}: {
    local: boolean;
    participantId: string;
    firstName: string;
    lastName: string;
    background: string;
    width: number;
    activeSpeakerAttendeeId: string | null;
}) => {
    const { isParticipantIdInRoster, getChimeIdInRoster } = useRoster();
    const publications = usePublications(participantId);
    const [networkQuality, setNetworkQuality] = useState(5);
    const attendee = getChimeIdInRoster(participantId);
    const { muted } = useAttendeeAudioStatus(attendee?.chimeAttendeeId || "");
    const { isAudioEnabled } = useLocalAudio();
    const devices = useVideoDevices();
    const hasVideoDevice = devices.length !== 0;

    const isActiveSpeaker =
        activeSpeakerAttendeeId === attendee?.chimeAttendeeId;

    useEffect(() => {
        // Implement network quality check using Amazon Chime SDK
    }, [participantId]);

    useEffect(() => {
        if (local) {
            Sentry.addBreadcrumb({
                message: `Network quality: ${networkQuality}`,
                level: "info",
            });
        }
    }, [local, networkQuality]);

    let bg, border, fallback;

    if (isParticipantIdInRoster(participantId)) {
        border = "1px solid transparent";
        if (!hasVideoDevice && local) {
            bg = "#F3F4F7";
            fallback = <Image w="40%" src={NoVideo} />;
        } else {
            bg = background;
            fallback = (
                <Text fontSize="6xl">{`${firstName[0]}${lastName[0]}`}</Text>
            );
        }
    } else {
        bg = "white";
        border = "1px dashed rgba(0,0,0,.2)";
        fallback = (
            <Image
                w="40%"
                src={BlankAvatarGrey}
                alt={"Participant without an image or video"}
            />
        );
    }

    const videoContainerRef = useRef<HTMLDivElement>(null);
    const [activeSpeakerBBox, setActiveSpeakerBBox] = useState<{
        width: number;
        height: number;
        left: number;
        top: number;
    } | null>(null);

    const updateSpeakerBoxSize = () => {
        const videoContainer = videoContainerRef.current;

        if (videoContainer) {
            const activeSpeakerBoxMargin = 4;
            const boundingBox = videoContainer.getBoundingClientRect();
            const width = boundingBox.width;
            const height = boundingBox.height;
            setActiveSpeakerBBox({
                width: width + activeSpeakerBoxMargin * 2,
                height: height + activeSpeakerBoxMargin * 2,
                left: boundingBox.left - activeSpeakerBoxMargin,
                top: boundingBox.top - activeSpeakerBoxMargin,
            });
        }
    };

    const videoContainerObserver = useMemo(
        () =>
            new ResizeObserver(() => {
                updateSpeakerBoxSize();
            }),
        [],
    );

    useEffect(() => {
        const videoContainer = videoContainerRef.current;
        updateSpeakerBoxSize();

        window.addEventListener("resize", updateSpeakerBoxSize);
        videoContainer?.parentElement?.addEventListener(
            "scroll",
            updateSpeakerBoxSize,
        );

        videoContainerObserver.observe(videoContainerRef.current as Element);

        return () => {
            window.removeEventListener("resize", updateSpeakerBoxSize);
            videoContainer?.parentElement?.removeEventListener(
                "scroll",
                updateSpeakerBoxSize,
            );
            videoContainerObserver.disconnect();
        };
    }, [
        videoContainerRef,
        publications,
        isActiveSpeaker,
        videoContainerObserver,
    ]);

    return (
        <>
            <Portal>
                {isActiveSpeaker ? (
                    <Box
                        border="2px solid #47C26C"
                        borderRadius="10px"
                        position="fixed"
                        zIndex={1}
                        pointerEvents="none"
                        {...activeSpeakerBBox}
                    />
                ) : null}
            </Portal>
            <Tooltip
                hasArrow
                label={`${firstName} ${lastName} ${
                    participantId ? "" : "has not joined video"
                }`}
            >
                <AspectRatio
                    ref={videoContainerRef}
                    w={`${width}px`}
                    minH="75px"
                    bg={bg}
                    border={border}
                    zIndex={1}
                    ratio={3.5 / 3}
                    marginY={"4px"}
                    borderRadius={"8px"}
                    overflow={"hidden"}
                >
                    <Box>
                        {fallback}
                        {publications.map((publication) => {
                            return (
                                <Publication
                                    key={publication.tileId}
                                    publication={publication}
                                    local={local}
                                ></Publication>
                            );
                        })}
                        <Flex
                            borderRadius="4px"
                            bg="rgba(0,0,0,.5)"
                            paddingX="5px"
                            color="white"
                            fontSize="sm"
                            left="4px"
                            bottom="4px"
                            position="absolute"
                            zIndex={10}
                        >
                            {(participantId && muted) ||
                            (local && !isAudioEnabled) ? (
                                <Image
                                    src={MicOff}
                                    alt={`Participant's microphone is muted`}
                                    marginRight="5px"
                                />
                            ) : null}
                            <Text>{firstName}</Text>
                        </Flex>
                        {participantId && networkQuality <= 1 ? (
                            <Image
                                right="4px"
                                bottom="4px"
                                position="absolute"
                                zIndex={10}
                                src={BadInternet}
                                borderRadius="10px"
                                backgroundColor="rgba(0,0,0,.5)"
                                padding="2px"
                                alt={`Bad network quality`}
                            />
                        ) : null}
                    </Box>
                </AspectRatio>
            </Tooltip>
        </>
    );
};
