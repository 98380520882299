import { Button, Text } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { createRef, useEffect } from "react";
import { meetingSession } from "../immersionState";
import { leaveMeeting } from "../video_chime/utils";
import { ExitModal } from "./ExitModal";
import { useAtom } from "jotai";

export const LeaveVideoModal: React.FC<{
    show: boolean;
    onStay: () => void;
    onLeave: () => void;
}> = ({ show, onStay, onLeave }) => {
    const [meeting, setMeeting] = useAtom(meetingSession);

    const buttonRef = createRef<HTMLButtonElement>();

    // When the "Leave video" button loads, set it as focus (in case user hits "Enter")
    useEffect(() => {
        buttonRef?.current?.focus();
    }, [buttonRef]);

    const leaveVideoRoom = async () => {
        try {
            if (meeting) {
                leaveMeeting(meeting);
                setMeeting(null);
            }
        } catch (e: any) {
            Sentry.captureException(e);
        }
        onLeave();
    };
    return (
        <ExitModal
            show={show}
            title={"Say goodbye! 👋"}
            label={
                <Text color="gray.500" fontSize={"16px"} fontWeight={400}>
                    You’re about to go off video and continue on your own!
                </Text>
            }
            onStay={onStay}
            exitButton={
                <Button colorScheme="black" onClick={() => leaveVideoRoom()}>
                    Continue
                </Button>
            }
            stayButtonLabel={"Stay"}
            id={"leave-video-modal"}
        />
    );
};
