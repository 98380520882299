import { ProductTourStep } from "./immersion/immersionState";

export const PARTICIPANT_BACKGROUNDS = [
    "positive.-2",
    "purple.-2",
    "blue.300",
    "info.-2",
    "warning.-1",
    "danger.-2",
];

export const PARTICIPANT_BACKGROUNDS_LIGHT = [
    "positive.-4",
    "purple.-4",
    "wise blue.-4",
    "info.-4",
    "warning.-5",
    "danger.-4",
];

export const PRODUCT_TOUR_TOOLTIPS: { [key in ProductTourStep]: string } = {
    [ProductTourStep.OrientationVideo]: "",
    [ProductTourStep.Intro]: "",
    [ProductTourStep.Workspace]:
        "In the center of the screen, your group will complete different activities together.",
    [ProductTourStep.Video]:
        "You will work together as a small group (2-5 people). You will join them in a moment!",
    [ProductTourStep.ProgressTracker]:
        "You will jointly go through a series of steps. Use this tracker to view progress and stay in sync as a group.",
    [ProductTourStep.Instructions]:
        "As you move through the steps, you will tackle various group and solo activities. This box will explain what to do on each step.",
    [ProductTourStep.Timer]:
        "This timer is a guideline, not a hard deadline. Use it to keep your group roughly on track.",
    [ProductTourStep.End]: "Click here now to move forward!",
};

type ButtonPosition =
    | "inside-right"
    | "inside-left"
    | "outside-left"
    | "outside-right"
    | undefined;
type AvatarPosition = "left" | "right" | undefined;
export const CHATBOT_STYLES = {
    getMessageStyles: ({
        isNthParticipantStep,
    }: {
        isNthParticipantStep: boolean;
    }) => ({
        default: {
            shared: {
                bubble: {
                    width: "100%",
                    // Subtract a right-padding
                    // (maxWidth is needed to override the default maxWidth of 80%)
                    maxWidth: "calc(100% - 12px)",
                    marginLeft: "0px",
                    backgroundColor: "unset",
                    marginTop: "4px",
                    marginBottom: "12px",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    lineHeight: "20px",
                    boxSizing: "border-box",
                    gridColumn: "2 / 3",
                    gridRow: "2 / 3",
                    padding: "0px",
                },
                outerContainer: {
                    marginLeft: "8px",
                    marginRight: "8px",
                    paddingLeft: isNthParticipantStep ? "15%" : "8px",
                    paddingRight: isNthParticipantStep ? "15%" : "8px",
                },
                innerContainer: {
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "60px minmax(10px, 1fr)",
                    gridTemplateRows: "29px 1fr",
                },
            },
            user: {
                bubble: {
                    color: "#5B5D61",
                },
            },
            ai: {
                outerContainer: {
                    borderRadius: "15px",
                    backgroundColor: "white",
                },
                bubble: {
                    color: "black",
                },
            },
        },
        loading: {
            bubble: {
                height: "20px",
                backgroundColor: "unset",
                marginTop: "4px",
                marginBottom: "12px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingLeft: "12px",
            },
            outerContainer: {
                marginLeft: "8px",
                marginRight: "8px",
                paddingLeft: isNthParticipantStep ? "15%" : "8px",
                paddingRight: isNthParticipantStep ? "15%" : "8px",
            },
            innerContainer: {
                width: "100%",
                display: "grid",
                gridTemplateColumns: "60px minmax(10px, 1fr)",
                gridTemplateRows: "29px 1fr",
            },
        },
    }),
    getAvatarStyles: ({
        chatbotAvatarUrl,
        userAvatarUrl,
    }: {
        chatbotAvatarUrl: string;
        userAvatarUrl: string;
    }) => ({
        default: {
            styles: {
                position: "left" as AvatarPosition,
                container: {
                    marginLeft: "12px",
                    marginRight: "5px",
                    height: "34px",
                    width: "34px",
                    gridColumn: "1/2",
                    gridRow: "1/3",
                },
            },
        },
        ai: {
            src: chatbotAvatarUrl,
            styles: {
                position: "left" as AvatarPosition,
                avatar: {
                    padding: "5px",
                    width: "24px",
                    height: "24px",
                    background: "#2B2D31",
                    borderRadius: "34px",
                },
            },
        },
        user: {
            src: userAvatarUrl,
            styles: {
                position: "left" as AvatarPosition,
                avatar: {
                    padding: "1px",
                    width: "31px",
                    height: "31px",
                    background: "#2B2D31",
                    borderRadius: "34px",
                },
            },
        },
    }),
    getNameStyles: ({
        currentlyViewingName,
    }: {
        currentlyViewingName: string;
    }) => ({
        default: {
            style: {
                color: "#000",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "17px",
                gridColumn: "2 / 3",
                gridRow: "1 / 2",
                alignSelf: "end",
            },
        },
        ai: {
            text: "Chatbot",
        },
        user: {
            text: currentlyViewingName,
            position: "left" as AvatarPosition,
        },
    }),
    getSubmitButtonStyles: ({
        isNthParticipantStep,
    }: {
        isNthParticipantStep: boolean;
    }) => {
        const arrowSvg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M3 7L8 2M8 2L13 7M8 2V14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>';
        const stopSvg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4.4C2.25 3.76348 2.50286 3.15303 2.95294 2.70294C3.40303 2.25286 4.01348 2 4.65 2H11.85C12.4865 2 13.097 2.25286 13.5471 2.70294C13.9971 3.15303 14.25 3.76348 14.25 4.4V11.6C14.25 12.2365 13.9971 12.847 13.5471 13.2971C13.097 13.7471 12.4865 14 11.85 14H4.65C4.01348 14 3.40303 13.7471 2.95294 13.2971C2.50286 12.847 2.25 12.2365 2.25 11.6V4.4Z" fill="white"/></svg>';
        const loadingSvg =
            '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 8C0.5 7.60218 0.658035 7.22064 0.93934 6.93934C1.22064 6.65804 1.60218 6.5 2 6.5C2.39782 6.5 2.77936 6.65804 3.06066 6.93934C3.34196 7.22064 3.5 7.60218 3.5 8C3.5 8.39782 3.34196 8.77936 3.06066 9.06066C2.77936 9.34196 2.39782 9.5 2 9.5C1.60218 9.5 1.22064 9.34196 0.93934 9.06066C0.658035 8.77936 0.5 8.39782 0.5 8ZM6.5 8C6.5 7.60218 6.65804 7.22064 6.93934 6.93934C7.22064 6.65804 7.60218 6.5 8 6.5C8.39782 6.5 8.77936 6.65804 9.06066 6.93934C9.34196 7.22064 9.5 7.60218 9.5 8C9.5 8.39782 9.34196 8.77936 9.06066 9.06066C8.77936 9.34196 8.39782 9.5 8 9.5C7.60218 9.5 7.22064 9.34196 6.93934 9.06066C6.65804 8.77936 6.5 8.39782 6.5 8ZM12.5 8C12.5 7.60218 12.658 7.22064 12.9393 6.93934C13.2206 6.65804 13.6022 6.5 14 6.5C14.3978 6.5 14.7794 6.65804 15.0607 6.93934C15.342 7.22064 15.5 7.60218 15.5 8C15.5 8.39782 15.342 8.77936 15.0607 9.06066C14.7794 9.34196 14.3978 9.5 14 9.5C13.6022 9.5 13.2206 9.34196 12.9393 9.06066C12.658 8.77936 12.5 8.39782 12.5 8Z" fill="white"/></svg>';
        const defaultStyles = {
            marginBottom: "6px",
            marginRight: "10px",
            borderRadius: "6px",
            padding: "8px",
        };
        const hoverVariations = {
            padding: "9px",
            marginRight: "9px",
            marginBottom: "5px",
        };
        const clickVariations = {
            borderRadius: "8px",
        };
        return {
            position: "inside-right" as ButtonPosition,
            submit: {
                container: {
                    default: {
                        display: isNthParticipantStep ? "none" : "flex",
                        width: "16px",
                        height: "16px",
                        justifyContent: "center",
                        alignItems: "center",
                        ...defaultStyles,
                        background:
                            "linear-gradient(113deg, #6D54E8 28.93%, #1A6CE5 74.68%)",
                    },
                    hover: {
                        ...defaultStyles,
                        ...hoverVariations,
                        background:
                            "linear-gradient(113deg, #8C78ED 28.93%, #3F84E9 74.68%)",
                    },
                    click: {
                        ...defaultStyles,
                        ...clickVariations,
                        background:
                            "linear-gradient(113deg, #6D54E8 28.93%, #1A6CE5 74.68%)",
                    },
                },
                svg: {
                    content: arrowSvg,
                    styles: {
                        default: {
                            filter: "none",
                        },
                    },
                },
            },
            loading: {
                container: {
                    default: {
                        ...defaultStyles,
                        background: "unset",
                        backgroundColor: "#D7D9DC",
                    },
                },
                svg: {
                    content: loadingSvg,
                    styles: {
                        default: {
                            filter: "none",
                        },
                    },
                },
            },
            stop: {
                container: {
                    default: {
                        ...defaultStyles,
                        background: "unset",
                        backgroundColor: "#2B2D31",
                    },
                    hover: {
                        ...defaultStyles,
                        ...hoverVariations,
                        background: "unset",
                        backgroundColor: "#3B3E44",
                    },
                    click: {
                        ...defaultStyles,
                        ...clickVariations,
                        backgroundColor: "#2B2D31",
                    },
                },
                svg: {
                    content: stopSvg,
                    styles: {
                        default: {
                            filter: "none",
                        },
                    },
                },
            },
            disabled: {
                container: {
                    default: {
                        ...defaultStyles,
                        background: "unset",
                        backgroundColor: "#D7D9DC",
                        cursor: "default",
                    },
                },
                svg: {
                    styles: {
                        default: {
                            filter: "none",
                        },
                    },
                },
            },
        };
    },
    getTextInputStyles: ({
        isNthParticipantStep,
    }: {
        isNthParticipantStep: boolean;
    }) => ({
        styles: {
            text: {
                color: "#1D1E1E",
                fontFamily: "Inter",
                fontSize: "14px",
                lineHeight: "22px",
                maxHeight: "150px",
            },
            container: {
                display: isNthParticipantStep ? "none" : "flex",
                width: "100%",
                marginLeft: "8px",
                marginRight: "8px",
                alignItems: "center",
                minHeight: "32px",
                backgroundColor: "white",
                padding: "6px 6px 6px 10px",
                borderRadius: "12px",
                border: "none",
                boxShadow: "none",
            },
            focus: {
                boxShadow: "0px 0px 0px 2px rgba(109, 84, 232, 0.10)",
                border: "1px solid #C5BBF6",
                borderRadius: "12px",
            },
        },
        placeholder: {
            text: "Your message to the AI...",
            style: {
                color: "#787C87",
                fontFamily: "Inter",
                fontSize: "12px",
                lineHeight: "17px",
            },
        },
        characterLimit: 5000,
    }),
};
