import { useEffect, useState } from "react";
import { useAccount, useChatwootToken } from "./hooks/useAccount";
import { chatwootVisible } from "./appState";
import { useAtom } from "jotai";

export const Chatwoot = () => {
    const [visible] = useAtom(chatwootVisible);
    const [chatwootReady, setChatwootReady] = useState(false);

    const { data: account } = useAccount();
    const chatwootToken = useChatwootToken();
    useEffect(() => {
        // Add Chatwoot Settings
        //@ts-ignore
        window.chatwootSettings = {
            hideMessageBubble: false,
            position: "right", // This can be left or right
            showPopoutButton: true, // This will show the popout button
            locale: "en", // Language to be set
            type: "standard", // [standard, expanded_bubble]
        };
        // Code from https://support.sparkwise.co/app/accounts/1/settings/inboxes/1
        (function (doc: any, tag: any) {
            var BASE_URL = "https://support.sparkwise.co";
            var g = doc.createElement(tag),
                s = doc.getElementsByTagName(tag)[0];

            g.src = BASE_URL + "/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            s.parentNode.insertBefore(g, s);
            g.onload = function () {
                //@ts-ignore
                window.chatwootSDK.run({
                    websiteToken: "Q9HhVACPJWCEi98ciaHvPg6L",
                    baseUrl: BASE_URL,
                });
            };
        })(document, "script");
    }, [account, chatwootToken]);

    useEffect(() => {
        const setReady = () => {
            setChatwootReady(true);
        };
        window.addEventListener("chatwoot:ready", setReady);
        return () => window.removeEventListener("chatwoot:ready", setReady);
    }, [setChatwootReady]);

    useEffect(() => {
        if (chatwootReady) {
            //@ts-ignore
            window.$chatwoot.toggleBubbleVisibility(visible ? "show" : "hide");
        }
        if (account && chatwootToken && chatwootReady) {
            //@ts-ignore
            window.$chatwoot.setUser(account.emails[0].email, {
                email: account.emails[0].email,
                name: `${account.firstName} ${account.lastName}`,
                avatar_url: account.avatarUrl,
                company_name: account.organization.name,
                identifier_hash: chatwootToken,
            });
        }
    }, [account, visible, chatwootToken, chatwootReady]);

    useEffect(() => {
        //@ts-ignore
        if (window.$chatwoot && chatwootReady) {
            //@ts-ignore
            window.$chatwoot.toggleBubbleVisibility(visible ? "show" : "hide");
        }
    }, [visible, chatwootReady]);

    return null;
};
