import NotFound404 from "design/assets/NotFound404.svg";
import { Button, Center, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export const NotFound = () => {
    const navigate = useNavigate();
    return (
        <Center
            w="100vw"
            h="100vh"
            position="fixed"
            top="0"
            left="0"
            bg="white"
        >
            <Stack w="665px">
                <Image h="245px" src={NotFound404} />
                <Text
                    fontFamily="Proxima Nova"
                    fontSize="40px"
                    fontWeight="semibold"
                    lineHeight="60px"
                    color="blue.500"
                    textAlign="center"
                >
                    Learning is a quest into the Unknown
                </Text>
                <Text
                    fontFamily="Proxima Nova"
                    fontSize="24px"
                    lineHeight="36px"
                    color="dark.700"
                    textAlign="center"
                >
                    Sometimes we may take a detour, but that's ok. Embrace the
                    unexpected, as you navigate back to your learning journey.
                </Text>
                <Flex justifySelf={"center"} justifyContent={"center"}>
                    <Button
                        mt="40px"
                        w={"200px"}
                        bg="gray.900"
                        color="white"
                        _hover={{
                            bg: "gray.700",
                        }}
                        onClick={() => navigate("/")}
                    >
                        Go back to dashboard
                    </Button>
                </Flex>
            </Stack>
        </Center>
    );
};
