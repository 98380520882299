import {
    Box,
    Flex,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import Chrome from "design/assets/Chrome.svg";
import Edge from "design/assets/Edge.svg";
import Firefox from "design/assets/Firefox.svg";
import Logo from "design/assets/Logo.svg";
import WrongDevice from "design/assets/WrongDevice.svg";
import { useEffect } from "react";

export const BrowserErrorModal = () => {
    const {
        isOpen: isBrowserOpen,
        onOpen: onBrowserOpen,
        onClose: onBrowserClose,
    } = useDisclosure();
    const {
        isOpen: isDeviceOpen,
        onOpen: onDeviceOpen,
        onClose: onDeviceClose,
    } = useDisclosure();

    useEffect(() => {
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            onDeviceOpen();
        } else if (
            (navigator.userAgent.indexOf("Safari") > -1 &&
                navigator.userAgent.indexOf("Chrome") <= -1) ||
            // @ts-ignore : Detect IE
            window.document.documentMode
        ) {
            onBrowserOpen();
        }
    }, []);

    return (
        <Flex>
            <Modal
                isOpen={isBrowserOpen}
                onClose={onBrowserClose}
                size="2xl"
                closeOnOverlayClick={false}
            >
                <ModalOverlay></ModalOverlay>
                <ModalContent>
                    <ModalHeader fontSize="36px" verticalAlign={"center"}>
                        It's not you - it's your browser
                    </ModalHeader>
                    <ModalBody fontSize="14px">
                        <Text display="inline">
                            To experience this immersion in all its splendor, we
                            recommend you switch to a supported browser. Want to
                            enter anyway?{" "}
                        </Text>
                        <Link
                            display="inline"
                            textDecoration="underline"
                            fontWeight={"semibold"}
                            onClick={onBrowserClose}
                        >
                            Proceed with caution
                        </Link>
                        <Box
                            bg="blue.50"
                            padding="28px"
                            borderRadius="20px"
                            marginTop="20px"
                        >
                            <Text fontWeight={600} fontSize="16px">
                                Our favorite browsers so far
                            </Text>
                            <Flex
                                justifyContent="center"
                                gap="40px"
                                marginTop="20px"
                            >
                                <Box>
                                    <Image margin="auto" src={Chrome}></Image>
                                    <Text
                                        textAlign="center"
                                        marginTop="10px"
                                        fontWeight={600}
                                        fontSize="14px"
                                    >
                                        Chrome
                                    </Text>
                                </Box>
                                <Box>
                                    <Image margin="auto" src={Edge}></Image>
                                    <Text
                                        textAlign="center"
                                        marginTop="10px"
                                        fontWeight={600}
                                        fontSize="14px"
                                    >
                                        Edge
                                    </Text>
                                </Box>

                                <Box>
                                    <Image margin="auto" src={Firefox}></Image>
                                    <Text
                                        textAlign="center"
                                        marginTop="10px"
                                        fontWeight={600}
                                        fontSize="14px"
                                    >
                                        Firefox
                                    </Text>
                                </Box>
                            </Flex>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={isDeviceOpen}
                onClose={onDeviceClose}
                size="full"
                closeOnOverlayClick={false}
            >
                <ModalOverlay></ModalOverlay>
                <ModalContent>
                    <ModalBody
                        fontSize="14px"
                        alignItems="center"
                        display="flex"
                        flexDir={"column"}
                    >
                        <Image src={Logo} margin="20px" />
                        <Image src={WrongDevice} mt="20px" />
                        <Text
                            fontWeight="bold"
                            fontSize="25px"
                            margin="20px"
                            textAlign="center"
                        >
                            Please join on a computer
                        </Text>
                        <Text display="inline" maxW="500px" textAlign="center">
                            Sparkwise requires a laptop/desktop. Please switch
                            your device and go to{" "}
                            <Link
                                href="https://app.sparkwise.co"
                                color="blue.500"
                            >
                                app.sparkwise.co
                            </Link>{" "}
                            to join the session.
                        </Text>

                        <Link
                            display="inline"
                            textDecoration="underline"
                            fontWeight={"semibold"}
                            onClick={onDeviceClose}
                            marginTop="30px"
                        >
                            Proceed with caution
                        </Link>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    );
};
