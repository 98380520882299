import { AudioVideoFacade } from "amazon-chime-sdk-js";
import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useEffect,
    useState,
} from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    currentAudioInputDeviceId,
    isAudioEnabledState,
    localAudioTrackState,
    meetingSession,
} from "../../immersionState";
import { useLocalAudioNoiseCancellation } from "../hooks/useLocalAudioNoiseCancellation";

export interface LocalAudioContext {
    isAudioEnabled: boolean;
    toggleMute: () => void;
    setMute: (setTo: boolean) => void;
    audioTrack: MediaStreamTrack | null;
    setEnableAudioMonitoring: (setValue: boolean) => void;
    isLoading: boolean;
}

export const LocalAudioContext = createContext<LocalAudioContext>(
    null as unknown as LocalAudioContext,
);

export const LocalAudioContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [enableAudioMonitoring, setEnableAudioMonitoring] =
        useState<boolean>(false);
    const [isAudioEnabled, setIsAudioEnabled] =
        useRecoilState(isAudioEnabledState);
    const [audioTrack, setAudioTrack] = useRecoilState(localAudioTrackState);

    const currentAIDeviceId = useRecoilValue(currentAudioInputDeviceId);
    const meetingManager = useRecoilValue(meetingSession);
    const { getNoiseCancellingDevice } = useLocalAudioNoiseCancellation();
    const audioVideo = meetingManager?.audioVideo;

    useEffect(() => {
        if (!audioVideo) {
            return;
        }
        const muteUnmuteCallback = (localMuted: boolean): void => {
            setIsAudioEnabled(!localMuted);
        };

        audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(
            muteUnmuteCallback,
        );

        return (): void => {
            audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(
                muteUnmuteCallback,
            );
        };
    }, [audioVideo]);

    const muteAudio = async (audioVideo: AudioVideoFacade) => {
        console.log("[LocalAudio]: Mute audio");
        audioVideo.realtimeMuteLocalAudio();
    };

    const unmuteAudio = async (audioVideo: AudioVideoFacade) => {
        console.log("[LocalAudio]: Unmute audio");
        audioVideo.realtimeUnmuteLocalAudio();
    };

    const toggleMute = useCallback(async () => {
        if (!audioVideo) {
            return;
        }
        const isMuted = audioVideo.realtimeIsLocalAudioMuted();
        if (isMuted) {
            await unmuteAudio(audioVideo);
        } else {
            await muteAudio(audioVideo);
        }
    }, [audioVideo]);

    const setMute = useCallback(
        async (setTo: boolean) => {
            if (!audioVideo) {
                return;
            }
            const isMuted = audioVideo.realtimeIsLocalAudioMuted();
            if (isMuted && setTo) {
                await unmuteAudio(audioVideo);
            } else if (!isMuted && !setTo) {
                await muteAudio(audioVideo);
            }
        },
        [audioVideo],
    );

    useEffect(() => {
        (async () => {
            if (!currentAIDeviceId || !enableAudioMonitoring) {
                return;
            }
            if (!audioVideo || !currentAIDeviceId) {
                console.log(`[LocalAudio]: Unsetting local audio track`);
                setAudioTrack(null);
                return;
            }
            setIsLoading(true);
            try {
                const chosenAudioInputTransformDevice =
                    await getNoiseCancellingDevice();
                const mediaStream = await audioVideo?.startAudioInput(
                    chosenAudioInputTransformDevice || "",
                );
                const track = mediaStream?.getAudioTracks()[0] || null;
                if (track) {
                    console.log(`[LocalAudio]: Got track`);
                    setAudioTrack(track);
                }
                console.log(`[LocalAudio]: Audio track set for meeting`);
            } catch (error) {
                console.error("Error getting microphone track:", error);
                setAudioTrack(null);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [
        audioVideo,
        currentAIDeviceId,
        setAudioTrack,
        enableAudioMonitoring,
        getNoiseCancellingDevice,
    ]);

    useEffect(() => {
        if (audioVideo) {
            setEnableAudioMonitoring(true);
        }
    }, [setEnableAudioMonitoring, audioVideo]);

    const context: LocalAudioContext = {
        isAudioEnabled,
        toggleMute,
        setMute,
        audioTrack,
        setEnableAudioMonitoring,
        isLoading,
    };
    return (
        <LocalAudioContext.Provider value={context}>
            {children}
        </LocalAudioContext.Provider>
    );
};
