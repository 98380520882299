import { useEffect } from "react";
import {
    currentAudioOutputDeviceId,
    meetingSession,
} from "../../immersionState";
import { useAtomValue } from "jotai";

export const AudioTrack = () => {
    const meeting = useAtomValue(meetingSession);
    const audioVideo = meeting?.audioVideo;
    const outputDevice = useAtomValue(currentAudioOutputDeviceId);

    useEffect(() => {
        if (!audioVideo || !outputDevice) return;

        const audioElement = document.createElement("audio");
        audioElement.autoplay = true;
        audioElement.id = `audio-totalMix`;

        const audioBaseElement = document.getElementById(
            "chime-audio-elements",
        );
        if (audioBaseElement) {
            audioBaseElement
                .querySelectorAll(`audio[id="audio-totalMix"]`)
                .forEach((x) => {
                    x.remove();
                });
            audioBaseElement.appendChild(audioElement);
        }

        (async () => {
            await audioVideo.listAudioOutputDevices();
            await audioVideo.chooseAudioOutput(outputDevice);
            await audioVideo.bindAudioElement(audioElement);
            if (audioElement) {
                await audioElement.setSinkId(outputDevice);
            }
            if (audioElement.srcObject) {
                const mediaStream = audioElement.srcObject as MediaStream;
                const audioTracks = mediaStream.getAudioTracks();
                console.log(`Number of audio tracks: ${audioTracks.length}`);
                if (audioTracks.length > 0) {
                    console.log("Audio track details:", audioTracks[0]);
                } else {
                    console.log("No audio tracks found");
                }
            } else {
                console.log("No srcObject found on audio element");
            }
        })();

        return () => {
            audioVideo.unbindAudioElement();
            if (audioElement.parentNode) {
                audioElement.parentNode.removeChild(audioElement);
            }
        };
    }, [audioVideo, outputDevice]);

    return null;
};
