import { Flex, Stack, Tooltip, keyframes } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { createRef, useEffect, useState } from "react";
import { PRODUCT_TOUR_TOOLTIPS } from "../../../Constants";
import { Hotspot } from "../../Hotspot";
import { IMMERSION_SIZES } from "../../Immersion";
import {
    ProductTourStep,
    productTourStep as productTourStepState,
} from "../../immersionState";
import { ProductTourTooltip } from "../../ProductTourTooltip";
import { TourAudioVideoButtons } from "./TourAudioVideoButtons";
import { TourParticipant } from "./TourParticipant";

const uncoverFromTopToBottom = keyframes`
0% { 
    clip-path: inset(0 0 100% 0); 
}
100% { 
    clip-path: inset(0 0 0 0); 
}
`;

export const TourVideoSidebar = () => {
    const [productTourStep, setProductTourStep] = useAtom(productTourStepState);
    const showHotspot = productTourStep === ProductTourStep.Workspace;
    const showSidebar = productTourStep >= ProductTourStep.Video;

    const participants = [
        {
            firstName: "You",
            color: "blue.200",
        },
        {
            firstName: "Participant 2",
            color: "info.-3",
        },
        {
            firstName: "Participant 3",
            color: "purple.-3",
        },
        {
            firstName: "Participant 4",
            color: "warning.-3",
        },
    ];

    const { HEADER_HEIGHT, FOOTER_HEIGHT, STEP_MARGIN_TOP } = IMMERSION_SIZES;
    const VIDEO_SIDEBAR_BOTTOM_MARGIN = "8px";
    const maxH = `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT} - ${STEP_MARGIN_TOP} - ${VIDEO_SIDEBAR_BOTTOM_MARGIN})`;
    const [videoWidth, setVideoWidth] = useState(0);
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
        const updateWidth = () => {
            const videoHeightMultiplier = 4.75;
            let videoHeight =
                (ref.current?.clientHeight || 0) / videoHeightMultiplier;

            videoHeight = Math.min(Math.max(videoHeight, 75), 400);
            let newVideoWidth = (videoHeight * 3.5) / 3 + 2;
            newVideoWidth = Math.min(newVideoWidth, window.innerWidth * 0.15);

            setVideoWidth(newVideoWidth);
        };
        updateWidth();
        window.addEventListener("resize", updateWidth);

        return () => window.removeEventListener("resize", updateWidth);
    }, [ref]);

    return showSidebar ? (
        <Tooltip
            hasArrow
            placement="left-start"
            borderRadius="6px"
            bg="gray.800"
            offset={[20, 8]}
            label={
                <ProductTourTooltip
                    header="Audio and video"
                    description={PRODUCT_TOUR_TOOLTIPS[ProductTourStep.Video]}
                />
            }
            isOpen={productTourStep === ProductTourStep.Video}
        >
            <Stack
                w={`${videoWidth}px`}
                flexDirection="column"
                ref={ref}
                overflow={"hidden"}
                gap="8px"
                mt={STEP_MARGIN_TOP}
                mb={VIDEO_SIDEBAR_BOTTOM_MARGIN}
                alignItems="center"
                maxH={maxH}
                overflowY={"auto"}
                animation={`${uncoverFromTopToBottom} 0.5s`}
            >
                <TourAudioVideoButtons />
                {participants.map((participant, index) => {
                    return (
                        <TourParticipant
                            background={participant.color}
                            key={index}
                            firstName={`${participant.firstName}`}
                            width={videoWidth}
                        ></TourParticipant>
                    );
                })}
            </Stack>
        </Tooltip>
    ) : (
        <Flex
            direction="column"
            ref={ref}
            overflow={"hidden"}
            mt={STEP_MARGIN_TOP}
            mb={VIDEO_SIDEBAR_BOTTOM_MARGIN}
            alignItems="center"
            maxH={maxH}
            overflowY={"auto"}
            w={`${videoWidth}px`}
        >
            {showHotspot && (
                <Flex mt="40px">
                    <Hotspot
                        onClick={() => {
                            setProductTourStep(ProductTourStep.Video);
                        }}
                    />
                </Flex>
            )}
        </Flex>
    );
};
