import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { Error } from "../Error";
import { Loading } from "../Loading";
import { NotFound } from "../NotFound";
import {
    useSessionContentAsLoader,
    useStepType,
} from "./hooks/useSessionContent";
import { liveblocksRoom as liveblocksRoomState } from "./immersionState";
import {
    AllParticipantEtherpads,
    FullScreenIFrame,
    FullScreenImage,
    FullScreenVideo,
    ImmersionCoverSlide,
    Memento,
    ProductTourWorkspace,
    PromptIntroductions,
    RichtextBox,
    RichtextCard,
    SplitEtherpadsOptionalImage,
    SplitImageEtherpad,
    SplitResourceChatbot,
    SplitRichtextEtherpad,
    SplitVideoEtherpad,
    ViewSampleAnswer,
} from "./steps";

export const Step = () => {
    const stepType = useStepType();
    const sessionContent = useSessionContentAsLoader();
    const liveblocksRoom = useAtomValue(liveblocksRoomState);

    useEffect(() => {
        if (!liveblocksRoom || !sessionContent) return;

        return liveblocksRoom.subscribe("others", (others, event) => {
            if (event.type === "enter") {
                sessionContent.refetch();
            }
        });
    }, [liveblocksRoom]);

    // We're using sessionContent here to check loading/error state because
    // useStepType is not async and doesn't have it's own loading/error state.
    if (sessionContent?.isLoading) {
        return <Loading />;
    }
    if (!sessionContent || sessionContent?.isError) {
        return <Error text={sessionContent?.error.message}></Error>;
    }

    // Handle case where stepType is no longer loaded because we entered a /group
    // page, which triggers a refetch of sessionContent.
    if (!stepType) {
        return <Loading />;
    }

    switch (stepType) {
        case "Product tour":
            return <ProductTourWorkspace />;
        case "All participant Etherpads":
            return <AllParticipantEtherpads />;
        case "Editable Etherpad + Image":
            return <SplitImageEtherpad imagePosition="right" />;
        case "Etherpad (editable) + Chatbot":
            return <SplitResourceChatbot resourceType="etherpad" />;
        case "Full-screen editable Etherpad":
            return <FullScreenIFrame resourceType="etherpad" />;
        case "Full-screen Excalidraw":
            return <FullScreenIFrame resourceType="excalidraw" />;
        case "Full-screen editable GSheet":
            return <FullScreenIFrame resourceType="gsheet" />;
        case "Full-screen image":
            return <FullScreenImage />;
        case "Etherpad (editable) + Etherpad (editable)":
        case "Image (optional) + Etherpad (editable) + Etherpad (read-only)":
        case "Image (optional) + Nth participant Etherpad + viewer's Etherpad":
            return <SplitEtherpadsOptionalImage />;
        case "Image + Chatbot":
            return <SplitResourceChatbot resourceType="image" />;
        case "Image + editable Etherpad":
            return <SplitImageEtherpad imagePosition="left" />;
        case "Immersion cover slide":
            return <ImmersionCoverSlide />;
        case "Memento form":
            return <Memento />;
        case "Nth participant Chatbot":
            return (
                <FullScreenIFrame resourceType="chatbot" nthParticipantStep />
            );
        case "Nth participant Excalidraw":
            return (
                <FullScreenIFrame
                    resourceType="excalidraw"
                    nthParticipantStep
                />
            );
        case "Nth participant GSheet (read-only)":
            return (
                <FullScreenIFrame resourceType="gsheet" nthParticipantStep />
            );
        case "Prompt to introductions (Get started)":
            return <PromptIntroductions />;
        case "Richtext + Chatbot":
            return <SplitResourceChatbot resourceType="richtext" />;
        case "Richtext + Etherpad (editable)":
            return <SplitRichtextEtherpad />;
        case "Richtext in box":
            return <RichtextBox />;
        case "Richtext resource":
            return <RichtextCard />;
        case "Video file + editable Etherpad":
            return <SplitVideoEtherpad />;
        case "Video file w/caption side":
            return <FullScreenVideo />;
        case "View sample answer":
            return <ViewSampleAnswer />;
        default:
            // This case should only appear if the stepType has a value (so it has loaded)
            // but that value is an invalid step type.
            return <NotFound />;
    }
};
