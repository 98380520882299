import { DefaultModality } from "amazon-chime-sdk-js";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef } from "react";
import { useParticipants as useSessionParticipants } from "../../hooks/useImmersion";
import { meetingSession, rosterState } from "../../immersionState";

export type RosterAttendeeType = {
    chimeAttendeeId?: string | null;
    externalUserId?: string;
    name?: string;
};

export type RosterType = {
    [attendeeId: string]: RosterAttendeeType;
};

const useRoster = () => {
    const meeting = useAtomValue(meetingSession);
    const audioVideo = meeting?.audioVideo;
    const rosterRef = useRef<RosterType>({});
    const [roster, setRoster] = useAtom(rosterState);
    const sessionParticipants = useSessionParticipants();

    useEffect(() => {
        if (!audioVideo) {
            return;
        }

        const rosterUpdateCallback = async (
            chimeAttendeeId: string,
            present: boolean,
            externalUserId?: string,
        ): Promise<void> => {
            if (!present) {
                delete rosterRef.current[chimeAttendeeId];

                setRoster((currentRoster: RosterType) => {
                    const { [chimeAttendeeId]: _, ...rest } = currentRoster;
                    return { ...rest };
                });

                return;
            }

            const attendeeId = new DefaultModality(chimeAttendeeId).base();
            if (attendeeId !== chimeAttendeeId) {
                return;
            }

            const inRoster = rosterRef.current[chimeAttendeeId];
            if (inRoster) {
                return;
            }

            let attendee: RosterAttendeeType = { chimeAttendeeId };

            if (externalUserId) {
                attendee.externalUserId = externalUserId;
            }

            rosterRef.current[attendeeId] = attendee;

            // Update the roster first before waiting to fetch attendee info
            setRoster((oldRoster) => ({
                ...oldRoster,
                [attendeeId]: attendee,
            }));
            const participantData = sessionParticipants.find(
                (participant) => participant.user.id === externalUserId,
            );

            // Make sure that the attendee is still on the roster
            if (!rosterRef.current[attendeeId]) {
                return;
            }

            attendee = { ...attendee, ...participantData };
            setRoster((oldRoster) => ({
                ...oldRoster,
                [attendeeId]: attendee,
            }));
        };

        audioVideo.realtimeSubscribeToAttendeeIdPresence(rosterUpdateCallback);

        return () => {
            setRoster({});
            rosterRef.current = {};
            audioVideo.realtimeUnsubscribeToAttendeeIdPresence(
                rosterUpdateCallback,
            );
        };
    }, [audioVideo, meeting, setRoster]);

    const isParticipantIdInRoster = (participantId: string): boolean => {
        return Object.values(roster).some(
            (attendee) => attendee.externalUserId === participantId,
        );
    };

    const getChimeIdInRoster = (participantId: string) => {
        return Object.values(roster).find(
            (attendee) => attendee.externalUserId === participantId,
        );
    };

    return {
        roster,
        isParticipantIdInRoster,
        getChimeIdInRoster,
    };
};
export default useRoster;
