import {
    BackgroundBlurProcessor,
    BackgroundBlurVideoFrameProcessor,
    ConsoleLogger,
    DefaultVideoTransformDevice,
    Device,
    LogLevel,
    NoOpVideoFrameProcessor,
} from "amazon-chime-sdk-js";
import { useAtom, useAtomValue } from "jotai";
import React, { createContext, PropsWithChildren } from "react";
import {
    backgroundBlurSupportedState,
    currentVideoInputDeviceId,
    isBackgroundBlurEnabledState,
    isBackgroundBlurRequestedState,
    meetingSession,
} from "../../immersionState";

interface LocalBlurContext {
    canBlur: boolean;
    isBackgroundBlur: boolean;
    toggleBackgroundBlur: () => void;
    getBackgroundBlurDevice: () => Promise<
        Device | DefaultVideoTransformDevice | null
    >;
}

export const LocalBlurContext = createContext<LocalBlurContext>(
    null as unknown as LocalBlurContext,
);

export const LocalBlurContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const logger = new ConsoleLogger("LocalBlur", LogLevel.INFO);

    const deviceId = useAtomValue(currentVideoInputDeviceId);
    const [device, setDevice] =
        React.useState<DefaultVideoTransformDevice | null>(null);
    const session = useAtomValue(meetingSession);
    const isBlurRequested = useAtomValue(isBackgroundBlurRequestedState);

    /**
     * This handler listens for device id changes. Whenever a different device is selected, we must create a new
     * Processor and Transform Device. We also must destroy/stop any prior resources created.
     */
    React.useEffect(() => {
        if ((!isBlurRequested && !session) || !deviceId) {
            return;
        }
        logger.info(`Updating settings for device ${deviceId}`);
        let processor: BackgroundBlurProcessor | null = null;
        let transform: DefaultVideoTransformDevice | null = null;
        (async () => {
            processor = await initializeBackgroundBlur();
            setBackgroundBlurSupported(!!processor);
            transform = new DefaultVideoTransformDevice(
                logger,
                deviceId,
                processor ? [processor] : [],
            );
            setDevice(transform);
        })();
        return () => {
            (async () => {
                if (processor) {
                    logger.info(`Destroying old blur processor`);
                    await processor.destroy();
                }
                if (transform) {
                    logger.info(`Stopping device transform`);
                    await transform.stop();
                }
            })();
        };
    }, [deviceId, session, isBlurRequested]);

    const [isBackgroundBlur, setIsBackgroundBlur] = useAtom(
        isBackgroundBlurEnabledState,
    );

    const [backgroundBlurSupported, setBackgroundBlurSupported] = useAtom(
        backgroundBlurSupportedState,
    );

    const canBlur: boolean = !!backgroundBlurSupported;

    /**
     * Creates a background blur processor.
     * Absence of a processor indicates it is not supported by the browser.
     */
    const initializeBackgroundBlur =
        async (): Promise<BackgroundBlurProcessor | null> => {
            try {
                logger.info(`Creating background blur processor`);
                const createdProcessor =
                    await BackgroundBlurVideoFrameProcessor.create();
                if (createdProcessor instanceof NoOpVideoFrameProcessor) {
                    return null;
                } else {
                    return createdProcessor || null;
                }
            } catch (error) {
                logger.error(
                    `Error creating a background blur video frame processor device ${error}`,
                );
                return null;
            }
        };

    /**
     * Create a new Video Transform Device if blur is enabled/available for a given Device ID
     */
    const getBackgroundBlurDevice = React.useCallback(async () => {
        if (!device) {
            return null;
        }
        if (isBackgroundBlur) {
            return device;
        }
        return device.intrinsicDevice();
    }, [device, isBackgroundBlur]);

    const toggleBackgroundBlur = React.useCallback(async (): Promise<void> => {
        setIsBackgroundBlur(!isBackgroundBlur);
    }, [isBackgroundBlur, setIsBackgroundBlur]);

    const context: LocalBlurContext = {
        canBlur,
        isBackgroundBlur,
        toggleBackgroundBlur,
        getBackgroundBlurDevice,
    };
    return (
        <LocalBlurContext.Provider value={context}>
            {children}
        </LocalBlurContext.Provider>
    );
};
