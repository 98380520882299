import { trpc } from "../../hooks/useTRPC";
import { useAccount } from "../../hooks/useAccount";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import React from "react";

export const useImmersion = () => {
    const { id } = useParams();
    const immersionLoader = trpc.immersion.getImmersion.useQuery(
        {
            sessionId: id!,
        },
        { enabled: !!id },
    );
    if (id) {
        if (immersionLoader.error) {
            Sentry.captureException(immersionLoader.error);
        }
        return immersionLoader;
    } else {
        return null;
    }
};

export const useImmersionPostSessionRedirect = () => {
    const response = useImmersion();
    if (
        response &&
        response?.data &&
        response.data.postSessionRedirectUrl &&
        response.data.postSessionRedirectName
    ) {
        return {
            url: response.data.postSessionRedirectUrl,
            name: response.data.postSessionRedirectName,
            autoRedirect: response.data.postSessionAutoRedirect,
        };
    }
    return null;
};

export const useIsLiveOnboarding = () => {
    const immersion = useImmersion();
    return (
        immersion?.data?.featureFlagOverrides.some(
            (override) =>
                override.featureFlag.name === "Live onboarding" &&
                override.enabled,
        ) ?? false
    );
};

/**
 * A "catch-up" session is a fixed timeslot with 1 participant, where
 * the "Catch up" flag is turned on.
 *
 * @returns whether the current session is a catch-up session
 */
export const useIsCatchUp = () => {
    const immersion = useImmersion();
    return (
        immersion?.data?.featureFlagOverrides.some(
            (override) =>
                override.featureFlag.name === "Catch up" && override.enabled,
        ) ?? false
    );
};

/**
 * A "solo session" is when a user from a dynamic timeslot is
 * assigned to a group by themselves. (Differs from a "catch-up").
 *
 * It is a solo session if the user is the only participant assigned
 * to the group.
 *
 * @returns whether the current session is a solo session
 */
export const useIsSoloSession = () => {
    const { groupId } = useParams();
    const { data, error } = trpc.immersion.getGroup.useQuery(
        { groupId: groupId! },
        { enabled: !!groupId },
    );

    if (!groupId) return false;

    if (error) {
        Sentry.captureException(error);
    }

    // assume session is solo if data has not loaded yet
    // to avoid flashing join video modal or group icons
    if (!data?.participants?.length) return true;

    return data?.participants?.length === 1;
};

export const useIsFinalSession = () => {
    const immersion = useImmersion();
    return (
        immersion?.data?.featureFlagOverrides.some(
            (override) =>
                override.featureFlag.name === "Final session" &&
                override.enabled,
        ) ?? false
    );
};

export const useMementoReflectionQuestion = () => {
    const immersion = useImmersion();
    return (
        immersion?.data?.featureFlagOverrides.some(
            (override) =>
                override.featureFlag.name === "Memento: Add Reflection" &&
                override.enabled,
        ) ?? false
    );
};

export const useMementoSkillsQuestion = () => {
    const immersion = useImmersion();
    return (
        immersion?.data?.featureFlagOverrides.some(
            (override) =>
                override.featureFlag.name === "Memento: Add Skills" &&
                override.enabled,
        ) ?? false
    );
};

export const useMementoRemoveKeepChange = () => {
    const immersion = useImmersion();
    return (
        immersion?.data?.featureFlagOverrides.some(
            (override) =>
                override.featureFlag.name === "Memento: Remove Keep/Change" &&
                override.enabled,
        ) ?? false
    );
};

export type Participant = {
    user: {
        id: string;
        firstName: string | null;
        lastName: string | null;
        avatarUrl: string | null;
        emails: {
            email: string;
        }[];
    };
    id: string;
    index: number;
};

export const useParticipants = () => {
    const { groupId } = useParams();
    const { data, error } = trpc.immersion.getGroup.useQuery(
        { groupId: groupId! },
        { enabled: !!groupId },
    );
    if (!groupId) return [];

    if (error) {
        Sentry.captureException(error);
    }
    return data?.participants || [];
};

export const useParticipantsAsLoader = () => {
    const { groupId } = useParams();
    const loader = trpc.immersion.getGroup.useQuery(
        { groupId: groupId! },
        { enabled: !!groupId },
    );
    if (!groupId) return null;

    if (loader.error) {
        Sentry.captureException(loader.error);
    }
    return loader;
};

export const useSessionParticipant = () => {
    const { id, groupId } = useParams();
    const { data, error } = trpc.immersion.getSessionParticipant.useQuery(
        {
            sessionId: id!,
            groupId: groupId,
        },
        {
            enabled: !!id,
        },
    );
    React.useEffect(() => {
        if (error) {
            Sentry.captureException(error);
        }
    }, [error]);
    if (id) {
        return data;
    } else {
        return null;
    }
};

export const useSessionParticipantAsLoader = () => {
    const { id, groupId } = useParams();
    const loader = trpc.immersion.getSessionParticipant.useQuery(
        {
            sessionId: id!,
            groupId: groupId,
        },
        { enabled: !!id },
    );
    if (id) {
        if (loader.error) {
            Sentry.captureException(loader.error);
        }
        return loader;
    } else {
        return null;
    }
};

/**
 *
 * @returns whether the current user has ever visited any type of Sparkwise session, regardless of type or size.
 */
export const useHasVisitedAnySession = () => {
    const { id } = useParams();
    const hasVisitedLoader = trpc.immersion.getHasVisitedAnySession.useQuery(
        {
            sessionId: id!,
        },
        {
            enabled: !!id,
        },
    );
    if (id) {
        if (hasVisitedLoader.error) {
            Sentry.captureException(hasVisitedLoader.error);
        }
        return hasVisitedLoader.data;
    }
    return null;
};

/**
 * @returns whether the current user has done a session in a group with more than 1 person
 *
 */
export const useHasVisitedGroupSession = () => {
    const { id } = useParams();
    const hasVisitedLoader = trpc.immersion.getHasVisitedGroupSession.useQuery(
        {
            sessionId: id!,
        },
        { enabled: !!id },
    );
    if (id) {
        if (hasVisitedLoader.error) {
            Sentry.captureException(hasVisitedLoader.error);
        }
        return hasVisitedLoader.data;
    }
    return undefined;
};

export const useDialCode = () => {
    const sessionParticipant = useSessionParticipant();
    return sessionParticipant?.dialInCode;
};

/**
 * @returns the data for the current user
 */
export const useSelfData = (): SessionParticipant => {
    const user = useAccount();
    const sp = useSessionParticipant();

    if (!user.data || !sp) return {} as SessionParticipant;

    return {
        sessionParticipantUuid: sp.id,
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        avatarUrl: user.data.avatarUrl,
    };
};

/**
 * @returns the groupId for the current participant if one has been assigned.
 * Otherwise, returns null.
 */
export const useGroupId = () => {
    const sessionParticipant = useSessionParticipant();
    return sessionParticipant?.groupId ?? null;
};

type SessionParticipant = {
    sessionParticipantUuid: string;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;
};
