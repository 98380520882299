import { Box, Flex, Image, Text, Tooltip } from "@chakra-ui/react";
import TimerImg from "design/assets/Timer.svg";
import { useAtomValue } from "jotai";
import { PRODUCT_TOUR_TOOLTIPS } from "../Constants";
import { useIsProductTour } from "./hooks/useSessionContent";
import { TimerState, useTimer, useTimerState } from "./hooks/useTimer";
import {
    ProductTourStep,
    productTourStep as productTourStepState,
} from "./immersionState";
import { ProductTourTooltip } from "./ProductTourTooltip";

export const Timer = ({ total }: { total: number }) => {
    const timer = useTimer();
    const timerState = useTimerState();
    const isProductTour = useIsProductTour();
    const productTourStep = useAtomValue(productTourStepState);

    let color;
    let text;
    if (timerState === TimerState.Counting) {
        color = "blue.400";
    } else if (timerState === TimerState.AtTime) {
        color = "blue.400";
        text = "Time is up!";
    } else {
        color = "warning.500";
        text = "Time to move on!";
    }
    return (
        <Tooltip
            width="244px"
            borderRadius="6px"
            bg="gray.800"
            label={
                <ProductTourTooltip
                    header="Timer"
                    description={PRODUCT_TOUR_TOOLTIPS[ProductTourStep.Timer]}
                />
            }
            hasArrow
            placement="bottom"
            isOpen={isProductTour && productTourStep === ProductTourStep.Timer}
        >
            <Flex flexDirection="column">
                <Text
                    alignSelf="flex-end"
                    height="25px"
                    color={color}
                    fontWeight={"bold"}
                    fontSize="small"
                >
                    {text}
                </Text>
                <Box
                    w="100%"
                    bg="blue.50"
                    height="28px"
                    position="relative"
                    borderRadius="6px"
                    overflow="hidden"
                    border="gray.100"
                    borderWidth="1px"
                >
                    <Box
                        w={Math.min((100 * timer) / total, 100) + "%"}
                        bg={color}
                        height="100%"
                        position="absolute"
                    ></Box>
                    <Flex
                        position="absolute"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        padding="6px 8px"
                    >
                        <Image h="14px" src={TimerImg} alt="Timer icon" />
                        <Text fontSize="12px" lineHeight="normal">
                            {total / 60} min
                        </Text>
                    </Flex>
                </Box>
            </Flex>
        </Tooltip>
    );
};
