import { Box, Center, Text } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import React from "react";
import { videoPermissionState } from "../../../immersionState";
import { useVideoDevices } from "../../hooks/useVideoDevices";

export const NoCameraAlert: React.FC = () => {
    const devices = useVideoDevices();
    const hasVideoDevice = devices.length !== 0;
    const videoPermission = useAtomValue(videoPermissionState);
    if (hasVideoDevice || videoPermission === "denied") {
        return null;
    }
    return (
        <Center>
            <Box
                bgColor={"warning.-4"}
                borderRadius={"8px"}
                padding={"8px"}
                position={"absolute"}
                top={"8px"}
                left={"auto"}
                right={"auto"}
            >
                <Text fontSize={"12px"} fontFamily={"Inter"} fontWeight={400}>
                    Unable to access camera
                </Text>
            </Box>
        </Center>
    );
};
