import { MenuItemOption, MenuOptionGroup } from "@chakra-ui/react";
import { useAtom, useAtomValue } from "jotai";
import React from "react";
import {
    currentAudioInputDeviceId,
    localDevicesState,
} from "../../immersionState";
import { menuStyles } from "../JoinVideoModal";

export const MicrophoneDeviceListMenu: React.FC = () => {
    const [audioInputDevice, setAudioInputDevice] = useAtom(
        currentAudioInputDeviceId,
    );
    const devices = useAtomValue(localDevicesState);
    return (
        <MenuOptionGroup
            defaultValue={
                audioInputDevice ??
                devices?.find((device) => device.kind === "audioinput")
                    ?.deviceId ??
                "default"
            }
            title="Select Microphone"
            type="radio"
            {...menuStyles.menuOptionGroup}
        >
            {devices
                ?.filter((device) => device.kind === "audioinput")
                .map((device) => (
                    <MenuItemOption
                        key={`${device.deviceId}-1`}
                        value={device.deviceId}
                        onClick={() => {
                            setAudioInputDevice(device.deviceId);
                        }}
                        {...menuStyles.menuItem}
                    >
                        {device.label}
                    </MenuItemOption>
                ))}
        </MenuOptionGroup>
    );
};
