import { MenuDivider, MenuItemOption, MenuOptionGroup } from "@chakra-ui/react";
import { menuStyles } from "../JoinVideoModal";
import {
    currentAudioOutputDeviceId,
    localDevicesState,
} from "../../immersionState";
import { useAtom, useAtomValue } from "jotai";
import { useOutputAudioDevices } from "../hooks/useOutputAudioDevices";

export const SpeakerSelectorMenu = () => {
    const [audioOutputDevice, setAudioOutputDevice] = useAtom(
        currentAudioOutputDeviceId,
    );
    const devices = useAtomValue(localDevicesState);
    const outputDevices = useOutputAudioDevices();
    return (
        <>
            <MenuDivider
                display={outputDevices === null ? "none" : undefined}
            />
            <MenuOptionGroup
                defaultValue={
                    audioOutputDevice ??
                    devices?.find((device) => device.kind === "audiooutput")
                        ?.deviceId ??
                    "default"
                }
                title="Speaker"
                type="radio"
                display={outputDevices === null ? "none" : undefined}
                {...menuStyles.menuOptionGroup}
            >
                {(outputDevices || []).map((device) => (
                    <MenuItemOption
                        key={`${device.deviceId}-1`}
                        value={device.deviceId}
                        onClick={() => {
                            setAudioOutputDevice(device.deviceId);
                        }}
                        {...menuStyles.menuItem}
                    >
                        {device.label}
                    </MenuItemOption>
                ))}
            </MenuOptionGroup>
        </>
    );
};
