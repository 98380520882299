import { Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { PARTICIPANT_BACKGROUNDS_LIGHT } from "../../Constants";
import { useAccount } from "../../hooks/useAccount";
import {
    useParticipants as useSessionParticipants,
    useSelfData,
} from "../hooks/useImmersion";
import { useIsStepVideo } from "../hooks/useSessionContent";
import { IMMERSION_SIZES } from "../Immersion";
import { meetingSession } from "../immersionState";
import { AudioTrack } from "./components/AudioTrack";
import { AudioVideoButtons } from "./components/AudioVideoButtons";
import { Participant } from "./components/Participant";
import { useActiveSpeaker } from "./hooks/useActiveSpeaker";
import { useLocalAudio } from "./hooks/useLocalAudio";
import { useLocalVideo } from "./hooks/useLocalVideo";
import { useAtomValue } from "jotai";

export const VideoRoomChime = () => {
    const account = useAccount();
    const sessionParticipants = useSessionParticipants();
    const self = useSelfData();
    const ref = React.createRef<HTMLDivElement>();
    const [videoWidth, setVideoWidth] = useState(0);
    const isVideoContent = useIsStepVideo();
    const { HEADER_HEIGHT, FOOTER_HEIGHT, STEP_MARGIN_TOP } = IMMERSION_SIZES;
    const VIDEO_SIDEBAR_BOTTOM_MARGIN = "8px";
    const maxH = `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT} - ${STEP_MARGIN_TOP} - ${VIDEO_SIDEBAR_BOTTOM_MARGIN})`;
    const chimeSession = useAtomValue(meetingSession);
    const { toggleVideo } = useLocalVideo();
    const { toggleMute } = useLocalAudio();
    const activeSpeakerAttendeeId = useActiveSpeaker();

    useEffect(() => {
        /*if (process.env.REACT_APP_CHIME_LOGS) {
            const logger = new ConsoleLogger('chime-logger', LogLevel.INFO);
            let logs: string[] = JSON.parse(
                window.localStorage.getItem(
                    `chime-logs-${process.env.NODE_ENV}-${groupId}`,
                ) || "[]",
            );

            // Log logic for Chime (you might need to adjust based on the logging method available)
            logger.info = function (message) {
                const logMessage = `[Chime-logs] ${DateTime.now().toString()} ${message}`;
                logs.push(logMessage);
            };

            // Every 3 seconds save logs
            const saveInterval = setInterval(() => {
                console.info(`chime-logs-${process.env.NODE_ENV}-${groupId}`, JSON.stringify(logs));
            }, 3000);

            // After 1 minute of collecting logs in "logs" array, send them to the api server as one large batch.
            const publishInterval = setInterval(() => {
                sendLogs({
                    logs,
                    key: `${self.sessionParticipantUuid}-${DateTime.now().toString()}`,
                });
                logs = [];
            }, 60000);

            return () => {
                clearInterval(publishInterval);
                clearInterval(saveInterval);
            };
        }*/
    }, []);

    useEffect(() => {
        if (!chimeSession) return;
        // toggleVideo();
        // toggleMute();
    }, [chimeSession]);

    useEffect(() => {
        const updateWidth = () => {
            const videoHeightMultiplier =
                sessionParticipants.length >= 5 ? 5.75 : 4.75;
            let videoHeight =
                (ref.current?.clientHeight || 0) / videoHeightMultiplier;

            videoHeight = Math.min(Math.max(videoHeight, 75), 400);

            let newVideoWidth = (videoHeight * 3.5) / 3 + 2;
            newVideoWidth = Math.min(newVideoWidth, window.innerWidth * 0.15);

            setVideoWidth(newVideoWidth);
        };
        updateWidth();
        window.addEventListener("resize", updateWidth);

        if (isVideoContent) {
            window.dispatchEvent(new Event("resize"));
        }

        return () => window.removeEventListener("resize", updateWidth);
    }, [sessionParticipants, ref, isVideoContent]);

    const localSessionParticipant = sessionParticipants.find(
        (participant) => participant.user.id === self.sessionParticipantUuid,
    );

    if (!account.data) {
        return <div>Error...</div>;
    }

    return (
        <Stack
            w={`${videoWidth}px`}
            flexDirection="column"
            ref={ref}
            overflow={"hidden"}
            gap="8px"
            mt={`calc(${STEP_MARGIN_TOP} - 8px)`}
            mb={VIDEO_SIDEBAR_BOTTOM_MARGIN}
            alignItems="center"
            maxH={maxH}
            overflowY={"auto"}
        >
            <div id="chime-audio-elements" />
            <AudioTrack />
            {<AudioVideoButtons videoWidth={videoWidth} />}
            <Participant
                background={
                    PARTICIPANT_BACKGROUNDS_LIGHT[
                        localSessionParticipant?.index || 0
                    ]
                }
                local={true}
                key="local"
                participantId={self.sessionParticipantUuid}
                firstName={account.data.firstName || ""}
                lastName={account.data.lastName || ""}
                width={videoWidth}
                activeSpeakerAttendeeId={activeSpeakerAttendeeId}
            ></Participant>
            {sessionParticipants
                .filter(
                    (participant) =>
                        participant.id !== self.sessionParticipantUuid,
                )
                .map((sessionParticipant, index) => {
                    return (
                        <Participant
                            background={
                                PARTICIPANT_BACKGROUNDS_LIGHT[
                                    sessionParticipant.index
                                ]
                            }
                            local={false}
                            key={sessionParticipant.id}
                            participantId={sessionParticipant.id}
                            firstName={`${sessionParticipant.user.firstName}`}
                            lastName={`${sessionParticipant.user.lastName}`}
                            width={videoWidth}
                            activeSpeakerAttendeeId={activeSpeakerAttendeeId}
                        ></Participant>
                    );
                })}
        </Stack>
    );
};
