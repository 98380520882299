import { Flex } from "@chakra-ui/react";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { mementoFormSubmitted } from "../../immersionState";

export const MementoResource: React.FC<{
    url: string;
    name: string;
}> = (props) => {
    const { url, name } = props;

    const setFormSubmitted = useSetAtom(mementoFormSubmitted);

    useEffect(() => {
        setFormSubmitted(false);
        const listener = (event: MessageEvent) => {
            if (event.type !== "message") {
                return;
            }
            if (event.origin !== "https://submit.jotform.com") {
                return;
            }
            if (event.data.action !== "submission-completed") {
                return;
            }
            setFormSubmitted(true);
        };
        window.addEventListener("message", listener);

        return () => window.removeEventListener("message", listener);
    }, []);

    return (
        <Flex flex="1" justifyContent="center" overflowY="auto" p="1px">
            <Flex
                h="100%"
                w="100%"
                maxW={"752px"}
                outline={"1px solid"}
                outlineColor={"gray.100"}
                borderRadius={"20px"}
                overflow={"hidden"}
            >
                <iframe title={name} src={url} height="100%" width="100%" />
            </Flex>
        </Flex>
    );
};
