import {
    Box,
    Flex,
    Image,
    List,
    ListItem,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { ListBulletIcon } from "@heroicons/react/24/solid";
import CircleIcon from "design/assets/Circle.svg";
import CircleBlack from "design/assets/CircleBlack.svg";
import LockIcon from "design/assets/Lock.svg";
import StepCompleted from "design/assets/StepCompleted.svg";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGroupId } from "../hooks/useImmersion";
import { useSessionContent } from "../hooks/useSessionContent";
import { stepSidebarOpen } from "../immersionState";

export const StepsMenu = () => {
    const { id, stepNumber } = useParams();
    const sessionContent = useSessionContent();
    const ref = React.createRef<HTMLUListElement>();
    const [visible, setVisible] = useAtom(stepSidebarOpen);
    const navigate = useNavigate();
    let visited = Number(localStorage.getItem(`Visited-${id}`));
    const groupId = useGroupId();

    useEffect(() => {
        ref.current?.scrollTo(0, 36 * (Number(stepNumber) - 5));
    }, [stepNumber, visible]);
    return (
        <>
            <Box
                onClick={() => setVisible(!visible)}
                position="fixed"
                width="100%"
                height="100%"
                display={visible ? "" : "none"}
                bg="rgba(60, 62, 68, .7)"
                left="0"
                top="0"
                zIndex="10"
            />
            <Box
                margin="10px"
                position="fixed"
                bottom="60px"
                bg="white"
                left="10px"
                display={visible ? "" : "none"}
                borderColor="gray.200"
                borderWidth="1px"
                width="350px"
                opacity="100%"
                height="400px"
                borderRadius="12px"
                boxShadow="0px 16px 24px -4px rgba(6, 20, 45, .08)"
                zIndex="11"
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    padding="12px"
                >
                    <Flex alignItems="center">
                        <ListBulletIcon height="20px"></ListBulletIcon>
                        <Text
                            fontWeight="bold"
                            fontSize={"lg"}
                            marginLeft="10px"
                        >
                            Steps
                        </Text>
                    </Flex>
                    <Text
                        fontWeight="semibold"
                        onClick={() => setVisible(!visible)}
                        _hover={{
                            cursor: "pointer",
                        }}
                    >
                        Hide
                    </Text>
                </Flex>
                <List
                    role="list"
                    overflowY="scroll"
                    height="330px"
                    padding="10px"
                    width="100%"
                    ref={ref}
                >
                    {sessionContent?.map((step, index) => {
                        // skip cover slide
                        if (index === 0) return null;

                        let icon = (
                            <Image
                                src={StepCompleted}
                                alt="Completed step icon"
                            />
                        );
                        let background = "";
                        let fontColor = "black";
                        let fontWeight = "";
                        let hover = { cursor: "pointer" };
                        let stepUrl = groupId
                            ? `/immersion/${id}/group/${groupId}/${index}`
                            : `/immersion/${id}/intro/${index}`;
                        let onClick = () => {
                            setVisible(false);
                            navigate(stepUrl);
                        };
                        if (index === Number(stepNumber)) {
                            icon = (
                                <Image
                                    src={CircleIcon}
                                    alt="Current step icon"
                                />
                            );
                            background = "gray.700";
                            fontColor = "white";
                            fontWeight = "semibold";
                        } else if (index < Number(stepNumber)) {
                        } else if (index <= visited) {
                            icon = (
                                <Image
                                    src={CircleBlack}
                                    color="black"
                                    alt="Visited incomplete step icon"
                                />
                            );
                            fontColor = "gray.300";
                        } else {
                            icon = (
                                <Image src={LockIcon} alt="Locked step icon" />
                            );
                            fontColor = "gray.300";
                            hover = { cursor: "" };
                            onClick = () => void 0;
                        }
                        return (
                            <ListItem
                                key={index}
                                padding="6px"
                                background={background}
                                borderRadius="6px"
                                _hover={hover}
                                onClick={onClick}
                            >
                                <Tooltip
                                    label={`${index}. ${step.menuTitle}`}
                                    hasArrow
                                    placement="right"
                                    openDelay={1200}
                                >
                                    <Flex>
                                        {icon}
                                        <Text
                                            marginLeft="10px"
                                            color={fontColor}
                                            fontWeight={fontWeight}
                                            noOfLines={1}
                                        >
                                            {index}. {step.menuTitle}
                                        </Text>
                                    </Flex>
                                </Tooltip>
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </>
    );
};
