import { Button, Flex, Heading, Image, Link, Text } from "@chakra-ui/react";
import Sparkles from "design/assets/Sparkles.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useStepUrl } from "../hooks/useSessionContent";
import { Loading } from "../../Loading";

export const FirstSessionCoverSlide = () => {
    const navigate = useNavigate();
    const { stepNumber } = useParams();
    const productTourUrl = useStepUrl(Number(stepNumber) + 1);
    const enterImmersionUrl = useStepUrl(Number(stepNumber) + 2);

    if (!productTourUrl || !enterImmersionUrl) {
        return <Loading />;
    }

    return (
        <Flex
            w="100%"
            justifyContent="center"
            flexDirection="column"
            alignSelf="center"
            gap="56px"
        >
            <Flex flexDirection="column" alignItems="center" gap="16px">
                <Image
                    src={Sparkles}
                    alt="Sparkles icon"
                    w="100px"
                    h="100px"
                ></Image>
                <Heading textAlign="center" fontSize="32" fontWeight="600">
                    Welcome to your first session!
                </Heading>
            </Flex>
            <Flex flexDirection="column" alignItems="center" gap="20px">
                <Flex flexDir="column" alignItems="center">
                    <Text fontWeight="400" fontSize="14px" color="#5B5D61">
                        You are about to embark on a live group experience.
                    </Text>
                    <Text fontWeight="400" fontSize="14px" color="#5B5D61">
                        Before you start, we will show you how it works.
                    </Text>
                </Flex>
                <Button
                    borderRadius="8px"
                    w="100px"
                    colorScheme="blue"
                    size="md"
                    onClick={() => navigate(productTourUrl)}
                    data-testid="enter-immersion-button"
                    data-ph-capture-attribute-first-session-orientation="view"
                >
                    Let's Go!
                </Button>
                <Link
                    fontWeight="400"
                    fontSize="14px"
                    color="#787A80"
                    onClick={() => navigate(enterImmersionUrl)}
                    data-ph-capture-attribute-first-session-orientation="skip"
                >
                    Skip orientation
                </Link>
            </Flex>
        </Flex>
    );
};
