import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { currentAudioInputDeviceId } from "../../immersionState";
import { useAudioLevel } from "./useAudioLevel";
import { useLocalAudio } from "./useLocalAudio";

// Threshold for speaking level
const SPEAK_THRESHOLD = 7.5;

/**
 * This hook is used to determine if the user is talking while muted.
 *
 * Since Chime will stop audio from the main stream when muted, we must create a listener for the raw device.
 * There is still room for improvement, as we are accessing the mic. raw stream, and not the device which may have
 * noise-cancelling enabled.
 */
export const useIsTalkingWhileMuted = () => {
    const { isAudioEnabled } = useLocalAudio();
    const deviceId = useAtomValue(currentAudioInputDeviceId);

    const [audioTrack, setAudioTrack] = useState<MediaStreamTrack | null>(null);

    useEffect(() => {
        let stream: MediaStream | null = null;
        (async () => {
            if (!isAudioEnabled && deviceId) {
                stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: { exact: deviceId },
                    },
                });

                const audioTrack = stream.getAudioTracks()[0];
                setAudioTrack(audioTrack);
            }
        })();
        return () => {
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        };
    }, [isAudioEnabled]);

    const audioLevel = useAudioLevel(audioTrack);

    const [isTalking, setIsTalking] = useState<boolean>(false);

    // useEffect for hide and show tooltip
    useEffect(() => {
        if (audioLevel > SPEAK_THRESHOLD) {
            setIsTalking(true);
        } else {
            setIsTalking(false);
            const timeout = setTimeout(() => {
                setIsTalking(false);
            }, 1500);
            return () => clearTimeout(timeout);
        }
    }, [audioLevel]);

    return !isAudioEnabled && isTalking;
};
