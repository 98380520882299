import { Flex, Image, Tooltip } from "@chakra-ui/react";
import { useAtom, useAtomValue } from "jotai";
import React from "react";
import AudioErrorIcon from "../../../../assets/audio_error.svg";
import {
    isVideoEnabledState,
    videoPermissionState,
} from "../../../immersionState";
import { CameraButton } from "../../components/CameraButton";
import { useChimeModals } from "../../hooks/useChimeModals";
import { useVideoDevices } from "../../hooks/useVideoDevices";

export const VideoControlButton: React.FC = () => {
    const devices = useVideoDevices();
    const { cameraAccessModal, cameraPermissionsModal } = useChimeModals();
    const hasVideoDevice = devices.length !== 0;
    const [isVideoEnabled, setIsVideoEnabled] = useAtom(isVideoEnabledState);
    const videoPermission = useAtomValue(videoPermissionState);
    const showWarning = videoPermission === "denied" || !hasVideoDevice;
    return (
        <Flex
            w={"36px"}
            h={"36px"}
            position={"relative"}
            borderRadius={8}
            bg={"white"}
            ml={2}
            _hover={{
                cursor: "pointer",
                backgroundColor: "gray.50",
                color: "gray.800",
            }}
            onClick={() => {
                if (hasVideoDevice) {
                    setIsVideoEnabled(!isVideoEnabled);
                } else if (videoPermission === "denied") {
                    cameraPermissionsModal.onOpen();
                } else {
                    cameraAccessModal.onOpen();
                }
            }}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Tooltip
                hasArrow
                placement="top"
                label={
                    videoPermission === "denied"
                        ? "Allow access to camera"
                        : null
                }
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                bg="gray.800"
                color="gray.100"
                padding="8px"
                textAlign={"center"}
                borderRadius={"4px"}
                p={"8px"}
            >
                <Image
                    src={AudioErrorIcon}
                    display={showWarning ? "block" : "none"}
                    w="16px"
                    h="16px"
                    alt={"Video device alert"}
                    position={"absolute"}
                    top={"-8px"}
                    right={"-8px"}
                />
            </Tooltip>
            <CameraButton isActive={isVideoEnabled && hasVideoDevice} />
        </Flex>
    );
};
