import { useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useJoinVideoStepNumber } from "../hooks/useSessionContent";
import { liveblocksRoom as liveblocksRoomState } from "../immersionState";
import { PromptProps, SmartPromptTemplate } from "./SmartPromptTemplate";

export const GatherPrompt = (props: PromptProps) => {
    const { id } = useParams();
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [mostRecentTestResults, setMostRecentTestResults] = useState<
        boolean[]
    >([false, false, false]);
    const joinVideoStepNumber = useJoinVideoStepNumber();
    const liveblocksRoom = useAtomValue(liveblocksRoomState);

    const unmount = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    useEffect(() => {
        if (!joinVideoStepNumber || !liveblocksRoom) {
            return;
        }

        if (localStorage.getItem(`SmartPrompt-Gather-${id}`) === "true") {
            return;
        }

        intervalRef.current = setInterval(() => {
            const highestVisitedStep = Number(
                localStorage.getItem(`Visited-${id}`) || 1,
            );
            const selfInRoom = liveblocksRoom.getSelf();
            const othersInRoom = liveblocksRoom.getOthers()?.filter((other) => {
                // filter out those whose email ends with "-admin"
                // since those are viewers from "current sessions" page
                return !other.id?.endsWith("-admin");
            });

            // condition 1: is highest visited step > join video step number?
            const condition1 = highestVisitedStep > joinVideoStepNumber;

            // condition 2: is there > 1 person in the liveblocks room now?
            const condition2 = selfInRoom !== null && othersInRoom.length > 0;

            // condition 3: is the current user the only person on the their step?
            const condition3 = othersInRoom.every(
                (other) =>
                    other.presence?.stepNumber !==
                    selfInRoom?.presence?.stepNumber,
            );

            const allConditionsMet = condition1 && condition2 && condition3;

            setMostRecentTestResults((prev) => {
                const newResults = [...prev];
                newResults.shift();
                newResults.push(allConditionsMet);
                return newResults;
            });
        }, 10000);

        return unmount;
    }, [id, joinVideoStepNumber, liveblocksRoom]);

    useEffect(() => {
        if (mostRecentTestResults.every((result) => result)) {
            setIsVisible(true);
            localStorage.setItem(`SmartPrompt-Gather-${id}`, "true");
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }
    }, [mostRecentTestResults, id]);

    return isVisible ? (
        <SmartPromptTemplate {...props} close={() => setIsVisible(false)} />
    ) : null;
};
