import { DefaultMeetingSession } from "amazon-chime-sdk-js";

export async function leaveMeeting(meeting: DefaultMeetingSession) {
    try {
        meeting.audioVideo.stopContentShare();
        meeting.audioVideo.stopLocalVideoTile();
        meeting.audioVideo.unbindAudioElement();
        await meeting.deviceController.chooseAudioOutput(null);
        await meeting.deviceController.stopVideoInput();
        await meeting.deviceController.stopAudioInput();
        await meeting.deviceController.destroy();
        meeting.audioVideo.stop();
    } catch (error) {
        console.log(
            "MeetingManager failed to clean up media resources on leave",
        );
    }
}

export const getVideoDevices = (devices: MediaDeviceInfo[]) =>
    devices.filter((device) => device.kind === "videoinput");

export const getAudioDevices = (devices: MediaDeviceInfo[]) =>
    devices.filter((device) => device.kind === "audioinput");

export const getOutputAudioDevices = (devices: MediaDeviceInfo[]) =>
    devices.filter((device) => device.kind === "audiooutput");
