import * as Sentry from "@sentry/react";
import { DefaultMeetingSession } from "amazon-chime-sdk-js";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { useParticipantsAsLoader } from "../hooks/useImmersion";
import {
    liveblocksRoom as liveblocksRoomState,
    meetingSession,
} from "../immersionState";
import { leaveMeeting } from "../video_chime/utils";
import { VideoRoomChime } from "../video_chime/VideoRoomChime";

export const VideoSidebar = () => {
    const [meeting, setMeeting] = useAtom(meetingSession);
    const liveblocksRoom = useAtomValue(liveblocksRoomState);
    const sessionParticipants = useParticipantsAsLoader();

    /**
     * Disconnects from the current room.
     */
    const disconnectRoom = async (
        meeting: DefaultMeetingSession | null,
    ): Promise<boolean> => {
        Sentry.captureMessage("Leaving room.");
        if (meeting) {
            await leaveMeeting(meeting);
        }
        return !!meeting;
    };

    /**
     * Clears the current room and meeting.
     */
    const clearMeeting = (meeting: DefaultMeetingSession | null) => {
        if (meeting) {
            setMeeting(null);
        }
        return !!meeting;
    };

    // Leave the room when the component unmounts
    useEffect(() => {
        return () => {
            console.debug(`Disconnecting room.`);
            disconnectRoom(meeting).then((disconnected) => {
                if (disconnected) {
                    console.debug(`Room disconnected. Will clear meeting`);
                }
                const cleared = clearMeeting(meeting);
                if (cleared) {
                    console.debug(`All cleared!`);
                }
            });
        };
    }, [meeting]);

    /**
     * Hook on beforeunload event to leave the room.
     */
    useEffect(() => {
        const fn = () => disconnectRoom(meeting);
        window.addEventListener("beforeunload", fn);
        return () => {
            window.removeEventListener("beforeunload", fn);
        };
    }, [meeting]);

    useEffect(() => {
        if (!liveblocksRoom || !sessionParticipants) return;

        return liveblocksRoom.subscribe("others", (others, event) => {
            if (event.type === "enter") {
                sessionParticipants.refetch();
            }
        });
    }, [liveblocksRoom]);

    if (meeting) {
        return <VideoRoomChime></VideoRoomChime>;
    }
    return null;
};
