import { Box, Button, Flex, Text, ToastId, useToast } from "@chakra-ui/react";
import { useImmersionRemainingSeconds } from "./hooks/useImmersionRemainingSeconds";
import { useImmersionPostSessionRedirect } from "./hooks/useImmersion";
import { useIsPreImmersion } from "./hooks/useSessionContent";
import { PropsWithChildren, useEffect, useRef } from "react";

const Toast: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Box
            bg={"dark.800"}
            p={"10px"}
            borderRadius={"8px"}
            boxShadow={"0px 16px 24px -4px rgba(6, 20, 45, 0.08);"}
        >
            {children}
        </Box>
    );
};

export const ImmersionTimedPostSessionRedirect: React.FC = () => {
    const isPre = useIsPreImmersion();
    const remainingSeconds = useImmersionRemainingSeconds();
    const redirect = useImmersionPostSessionRedirect();
    const toast = useToast();

    const remainingMinutes = remainingSeconds
        ? Math.ceil(remainingSeconds / 60)
        : null;

    const toastIdRef = useRef<ToastId>();

    useEffect(() => {
        if (
            remainingSeconds === null ||
            remainingSeconds > 60 ||
            !redirect ||
            !redirect?.autoRedirect ||
            isPre
        ) {
            return;
        }
        if (toastIdRef.current && remainingSeconds === 0) {
            toast.close(toastIdRef.current);
            if (redirect?.url) {
                window.location.assign(redirect.url);
            }
        }
        if (!toastIdRef.current) {
            toastIdRef.current = toast({
                duration: null,
                isClosable: false,
                position: "top",
                render: ({ onClose }) => (
                    <FinalReminder
                        remainingSeconds={remainingSeconds}
                        onClose={onClose}
                    />
                ),
                onCloseComplete: () => {
                    if (redirect?.url) {
                        window.location.assign(redirect.url);
                    }
                },
            });
        } else {
            toast.update(toastIdRef.current, {
                render: ({ onClose }) => (
                    <FinalReminder
                        remainingSeconds={remainingSeconds}
                        onClose={onClose}
                    />
                ),
            });
        }
    }, [remainingSeconds, toastIdRef, redirect]);

    useEffect(() => {
        if (
            remainingMinutes === null ||
            !redirect ||
            !redirect?.autoRedirect ||
            isPre
        ) {
            return;
        }
        if (remainingMinutes === 15) {
            toast({
                duration: 10000,
                isClosable: false,
                position: "top",
                render: () => (
                    <Toast>
                        <Text
                            color={"white"}
                            fontSize={"16px"}
                            fontWeight={"400"}
                        >
                            Session will end automatically in <b>15 minutes</b>.
                        </Text>
                    </Toast>
                ),
            });
        }
        if (remainingMinutes === 5) {
            toast({
                duration: 10000,
                isClosable: false,
                position: "top",
                render: () => (
                    <Toast>
                        <Text
                            color={"white"}
                            fontSize={"16px"}
                            fontWeight={"400"}
                        >
                            <b>5 minutes</b> left — begin wrapping up!
                        </Text>
                    </Toast>
                ),
            });
        }
    }, [remainingMinutes]);

    useEffect(() => {
        return () => {
            if (toastIdRef.current) {
                toast.update(toastIdRef.current, {
                    onCloseComplete: undefined,
                });
                toast.close(toastIdRef.current);
            }
        };
    }, []);

    return null;
};

const FinalReminder: React.FC<{
    remainingSeconds: number;
    onClose: () => void;
}> = ({ remainingSeconds, onClose }) => {
    return (
        <Toast>
            <Flex gap={"32px"} alignItems={"center"}>
                <Text color={"white"} fontSize={"16px"} fontWeight={"400"}>
                    Session ending in <b>{remainingSeconds} seconds</b>.
                    Redirecting soon...
                </Text>
                <Button
                    colorScheme={"blue"}
                    color={"white"}
                    fontSize={"13px"}
                    fontWeight={600}
                    py={"6px"}
                    px={"4px"}
                    size={"sm"}
                    onClick={() => onClose()}
                    height={"auto"}
                    borderRadius={"4px"}
                >
                    Leave Now
                </Button>
            </Flex>
        </Toast>
    );
};
