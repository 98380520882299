import {
    Box,
    Flex,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useAtom, useAtomValue } from "jotai";
import React from "react";
import {
    currentVideoInputDeviceId,
    videoPermissionState,
} from "../../immersionState";
import { useVideoDevices } from "../hooks/useVideoDevices";
import { menuStyles } from "../JoinVideoModal";
import { BackgroundBlurSelector } from "./BackgroundBlurSelector";

export const VideoMenu: React.FC = () => {
    const [videoDevice, setVideoDevice] = useAtom(currentVideoInputDeviceId);
    const videoPermission = useAtomValue(videoPermissionState);
    const devices = useVideoDevices();
    const hasVideoDevice = devices.length !== 0;

    const firstDevice = devices[0] || null;
    const defaultDevice = videoDevice || firstDevice?.deviceId || "default";
    const currentDevice =
        devices.find((_) => _.deviceId === videoDevice)?.label ||
        (!hasVideoDevice ? "No camera detected" : undefined);

    return (
        <Flex flexDirection="column" gap="5px" width="49%">
            <Text fontSize="sm" fontWeight="semibold">
                Video
            </Text>
            <Menu>
                <Tooltip
                    hasArrow
                    placement="bottom"
                    label={
                        videoPermission === "denied"
                            ? "Permissions needed"
                            : null
                    }
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="400"
                    bg="gray.800"
                    color="gray.100"
                    padding="8px"
                    textAlign={"center"}
                    borderRadius={"4px"}
                    p={"8px"}
                >
                    <MenuButton
                        borderWidth="1px"
                        borderColor="gray.100"
                        borderRadius="6px"
                        disabled={videoPermission === "denied"}
                        bg={
                            videoPermission === "denied" ? "gray.25" : undefined
                        }
                        height={"36px"}
                    >
                        <Flex
                            height={"34px"}
                            padding="6px 10px 6px 8px"
                            justifyContent="space-between"
                            gap="12px"
                        >
                            <Text
                                height="34px"
                                whiteSpace="nowrap"
                                overflow={"hidden"}
                                textOverflow="ellipsis"
                                textAlign="left"
                                fontSize="14px"
                                lineHeight="22px"
                                color={
                                    videoPermission === "denied"
                                        ? "gray.200"
                                        : "gray.700"
                                }
                            >
                                {currentDevice}
                            </Text>
                            <Box alignSelf="center">
                                <ChevronDownIcon
                                    height="16px"
                                    color="gray.700"
                                />
                            </Box>
                        </Flex>
                    </MenuButton>
                </Tooltip>
                <MenuList minWidth="240px" maxH="410px" overflowY="auto">
                    <MenuOptionGroup
                        value={
                            videoDevice ||
                            (!hasVideoDevice ? "no-camera" : undefined)
                        }
                        defaultValue={defaultDevice}
                        title="Select Camera"
                        type="radio"
                        {...menuStyles.menuOptionGroup}
                    >
                        {!hasVideoDevice ? (
                            <MenuItemOption
                                key={"no-camera"}
                                value={"no-camera"}
                                {...menuStyles.menuItem}
                            >
                                No camera detected
                            </MenuItemOption>
                        ) : null}
                        {devices.map((device) => (
                            <MenuItemOption
                                key={device.deviceId}
                                value={device.deviceId}
                                onClick={() => {
                                    setVideoDevice(device.deviceId);
                                }}
                                {...menuStyles.menuItem}
                            >
                                {device.label}
                            </MenuItemOption>
                        ))}
                    </MenuOptionGroup>
                    <BackgroundBlurSelector />
                </MenuList>
            </Menu>
        </Flex>
    );
};
