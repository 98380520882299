import {
    Button,
    Flex,
    keyframes,
    Text,
    Tooltip,
    useDisclosure,
} from "@chakra-ui/react";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ListBulletIcon,
} from "@heroicons/react/24/solid";
import { useAtom } from "jotai";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PRODUCT_TOUR_TOOLTIPS } from "../../Constants";
import { Hotspot } from "../Hotspot";
import { IMMERSION_SIZES } from "../Immersion";
import { ProductTourTooltip } from "../ProductTourTooltip";
import {
    useGroupId,
    useImmersionPostSessionRedirect,
} from "../hooks/useImmersion";
import {
    useIsFinalStep,
    useIsPreImmersion,
    useIsProductTour,
    useIsStepMemento,
    useNextStepNumber,
    useSessionContentAsLoader,
    useShouldJoinVideo,
    useShouldJoinVideoAfterStep,
    useShouldLeaveVideoAfterStep,
    useStepContent,
} from "../hooks/useSessionContent";
import { TimerState, useTimerState } from "../hooks/useTimer";
import {
    mementoFormSubmitted,
    ProductTourStep,
    productTourStep as productTourStepState,
    stepSidebarOpen,
} from "../immersionState";
import { JoinVideoModalChime } from "../video_chime/JoinVideoModal";
import { LeaveVideoModal } from "./LeaveVideoModal";
import { RedirectModal } from "./RedirectModal";
import { StepsMenu } from "./StepsMenu";
import { SuccessfulCompletionModal } from "./SuccessfulCompletionModal";
import { Timeline } from "./Timeline";
import { ImmersionTimedPostSessionRedirect } from "../ImmersionTimedPostSessionRedirect";

const pulse = keyframes`
    0% { box-shadow: 0 0 0 6px rgba(241, 116, 55, 0.0); }
   50% { box-shadow: 0 0 0 6px rgba(241, 116, 55, 0.4); }
  100% { box-shadow: 0 0 0 6px rgba(241, 116, 55, 0.0); }
`;

export const Footer = () => {
    const { FOOTER_HEIGHT } = IMMERSION_SIZES;
    const [productTourStep, setProductTourStep] = useAtom(productTourStepState);
    const isProductTour = useIsProductTour();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [leaveVideoOpen, setLeaveVideoOpen] = useState(false);
    const sessionContentLoader = useSessionContentAsLoader();

    const [showCompletionModal, setShowCompletionModal] = useState<
        "completed" | "redirect" | "final" | null
    >(null);

    const timerState = useTimerState();
    const stepContent = useStepContent();
    const navigate = useNavigate();
    const { id, stepNumber } = useParams();
    const nextStepNumber = useNextStepNumber();
    const isPreImmersion = useIsPreImmersion();
    const [stepPanelVisible, setStepPanelVisible] = useAtom(stepSidebarOpen);
    const joinVideoButton = useShouldJoinVideoAfterStep();
    const leaveVideoButton = useShouldLeaveVideoAfterStep();
    const joinVideo = useShouldJoinVideo(true);
    const participantGroup = useGroupId();

    const isFinalStep = useIsFinalStep();

    // Memento related
    const [isMementoFormSubmitted, setIsMementoFormSubmitted] =
        useAtom(mementoFormSubmitted);
    const isMementoStep = useIsStepMemento();
    const postSessionRedirect = useImmersionPostSessionRedirect();
    const hasPostSessionRedirect = !!postSessionRedirect;

    const showTimeline =
        !isProductTour || productTourStep >= ProductTourStep.ProgressTracker;
    const showHotspotForTimeline =
        isProductTour && productTourStep === ProductTourStep.Video;
    const productTourInProgress =
        isProductTour && productTourStep < ProductTourStep.End;

    const sessionContentStillLoading = sessionContentLoader?.isLoading;

    let buttonStyles: any = {};
    if (productTourInProgress) {
        buttonStyles.borderColor = "gray.200";
        buttonStyles.borderWidth = "1px";
        buttonStyles.backgroundColor = "white";
        buttonStyles._hover = { backgroundColor: "gray.background" };
    } else if (timerState === TimerState.Counting) {
        buttonStyles.colorScheme = "black";
        buttonStyles.backgroundColor = "gray.800";
        buttonStyles._hover = {
            backgroundColor: "black",
        };
        buttonStyles.animation = undefined;
    } else if (timerState === TimerState.AtTime) {
        buttonStyles.colorScheme = "blue";
        buttonStyles.animation = undefined;
    } else {
        buttonStyles.colorScheme = "warning";
        buttonStyles.animation = `${pulse} 2s infinite`;
    }

    let nextStepUrl: string;
    let nextStepText: string;
    let buttonIcon: ReactElement | undefined = !productTourInProgress ? (
        <ChevronRightIcon height="18px" />
    ) : undefined;
    if (participantGroup) {
        nextStepUrl = `/immersion/${id}/group/${participantGroup}/${nextStepNumber}`;
        nextStepText = "Next Step";
    } else {
        if (joinVideoButton) {
            nextStepUrl = `/immersion/${id}/match`;
            nextStepText = "Join Group";
        } else {
            nextStepUrl = `/immersion/${id}/intro/${nextStepNumber}`;
            nextStepText = "Next Step";
        }
    }
    if (productTourInProgress) {
        nextStepText = "Skip tour";
    }

    const buttonAction = useRef<(() => void) | undefined>(undefined);

    const whiteButtonStyle = {
        borderColor: "gray.200",
        borderWidth: "1px",
        backgroundColor: "white",
        color: "gray.950",
        _hover: { backgroundColor: "gray.background" },
        transition: "background-color 0.2s",
    };

    const blackButtonStyle = {
        backgroundColor: "gray.800",
        color: "white",
        _hover: { backgroundColor: "black" },
        transition: "background-color 0.2s",
    };

    const triggerDelayedAction = useRef<boolean>(false);

    if (isMementoStep) {
        buttonIcon = undefined;
        nextStepText = "Continue";
        if (!isMementoFormSubmitted) {
            buttonStyles = whiteButtonStyle;
        } else {
            buttonStyles = blackButtonStyle;
            if (isFinalStep) {
                triggerDelayedAction.current = true;
            }
        }
    }

    if (isFinalStep) {
        if (hasPostSessionRedirect) {
            buttonAction.current = () => setShowCompletionModal("redirect");
            nextStepText = "Continue";
            nextStepUrl = "";
            buttonIcon = undefined;
        } else {
            nextStepText = "Continue";
            buttonAction.current = () => setShowCompletionModal("completed");
        }
    } else {
        buttonAction.current = undefined;
    }

    useEffect(() => {
        if (!triggerDelayedAction.current) {
            return;
        }
        const t = setTimeout(() => {
            if (buttonAction.current) {
                buttonAction.current();
            }
            triggerDelayedAction.current = false;
        }, 2000);
        return () => {
            clearTimeout(t);
        };
    }, [triggerDelayedAction.current, buttonAction]);

    const previousStepUrl = participantGroup
        ? `/immersion/${id}/group/${participantGroup}/${Number(stepNumber) - 1}`
        : `/immersion/${id}/intro/${Number(stepNumber) - 1}`;

    // Open up the video connection dialog on page refresh by default,
    // if the user is the right type & right spot in app.
    useEffect(() => {
        if (
            localStorage.getItem(`NoVideo-${id}`) !== "true" &&
            joinVideo &&
            !isOpen
        ) {
            onOpen();
        }
    }, [id, joinVideo]);

    if (isPreImmersion) {
        return <></>;
    }

    return (
        <>
            <Flex
                padding="28px 20px 20px 20px"
                borderTop="1px"
                borderColor="gray.100"
                h={FOOTER_HEIGHT}
                alignItems={"center"}
                justifyContent={"space-between"}
                backgroundColor="white"
                zIndex={2}
            >
                <Flex alignItems="center" gap="8px">
                    <Button
                        title={`${stepPanelVisible ? "Hide" : "Show"} steps menu`}
                        paddingX="3px"
                        onClick={() => setStepPanelVisible(!stepPanelVisible)}
                        bg="white"
                        color="gray.600"
                        _hover={{ background: "gray.100", color: "gray.800" }}
                    >
                        <ListBulletIcon height="20px"></ListBulletIcon>
                    </Button>
                    <Text w="200px" fontWeight="semibold" noOfLines={3}>
                        {stepNumber}. {stepContent.menuTitle}
                    </Text>
                </Flex>
                {showTimeline && (
                    <Timeline
                        joinVideoModalOpen={isOpen}
                        leaveVideoModalOpen={leaveVideoOpen}
                    />
                )}
                {showHotspotForTimeline && (
                    <Flex w="100%" ml="40px">
                        <Hotspot
                            onClick={() =>
                                setProductTourStep(
                                    ProductTourStep.ProgressTracker,
                                )
                            }
                        />
                    </Flex>
                )}
                <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    gap="12px"
                    width="200px"
                >
                    <JoinVideoModalChime isOpen={isOpen} onClose={onClose} />
                    {!isProductTour ? (
                        <Button
                            title="Previous step"
                            marginLeft="0"
                            onClick={() => navigate(previousStepUrl)}
                            background="white"
                            color="gray.600"
                            padding="8px"
                            _hover={{
                                background: "gray.100",
                                color: "gray.800",
                            }}
                        >
                            <ChevronLeftIcon height="18px"></ChevronLeftIcon>
                        </Button>
                    ) : (
                        productTourStep === ProductTourStep.Timer && (
                            <Flex ml="48px">
                                <Hotspot
                                    onClick={() =>
                                        setProductTourStep(ProductTourStep.End)
                                    }
                                />
                            </Flex>
                        )
                    )}
                    {!leaveVideoOpen && (
                        <Tooltip
                            borderRadius="6px"
                            bg="gray.800"
                            label={
                                <ProductTourTooltip
                                    description={
                                        PRODUCT_TOUR_TOOLTIPS[
                                            ProductTourStep.End
                                        ]
                                    }
                                />
                            }
                            hasArrow
                            placement="top-end"
                            isOpen={
                                isProductTour &&
                                productTourStep === ProductTourStep.End
                            }
                        >
                            <Button
                                isDisabled={sessionContentStillLoading}
                                marginRight="0"
                                borderRadius="8px"
                                onClick={() => {
                                    if (buttonAction.current) {
                                        return buttonAction.current();
                                    }
                                    if (joinVideoButton && participantGroup) {
                                        // Skip the product tour step if we've already seen it.
                                        navigate(nextStepUrl);
                                        onOpen();
                                    } else if (
                                        leaveVideoButton &&
                                        participantGroup
                                    ) {
                                        // onOpen_leaveVideoModal();
                                        setLeaveVideoOpen(true);
                                    } else {
                                        // Skip the product tour step if we've already seen it.
                                        navigate(nextStepUrl);
                                    }
                                }}
                                rightIcon={buttonIcon}
                                {...buttonStyles}
                            >
                                <Text fontWeight="semibold">
                                    {nextStepText}
                                </Text>
                            </Button>
                        </Tooltip>
                    )}
                </Flex>
                <StepsMenu></StepsMenu>
                <ImmersionTimedPostSessionRedirect />
                <RedirectModal
                    show={showCompletionModal === "redirect"}
                    onStay={() => setShowCompletionModal(null)}
                />
                <SuccessfulCompletionModal
                    show={showCompletionModal === "completed"}
                    onStay={() => setShowCompletionModal(null)}
                    onLeave={() => {
                        window.location.assign("/reviews/" + id);
                    }}
                />
                <LeaveVideoModal
                    show={leaveVideoOpen}
                    onStay={() => setLeaveVideoOpen(false)}
                    onLeave={() => {
                        setLeaveVideoOpen(false);
                        navigate(nextStepUrl);
                    }}
                />
            </Flex>
        </>
    );
};
