import { useStepContent } from "../hooks/useSessionContent";
import { useSelfData, useParticipants } from "../hooks/useImmersion";
import { Flex, Text, keyframes } from "@chakra-ui/react";
import { IFrameResource } from "./resources/IFrameResource";
import { HeaderType, CurrentlyViewing } from "./resources/ResourceHeader";
import { getEtherpadUrl } from "../../utils";

type EtherpadDetails = {
    url: string;
    etherpadName: string;
    headerType: HeaderType;
    sessionParticipantUuid: string;
    currentlyViewing?: CurrentlyViewing;
};

const genieEffectInWithBounce = keyframes`
0% {
    transform: scale(0.6) translateY(100%);
    opacity: 1;
}
37% {
    transform: scale(1.05) translateY(0%);
    opacity: 1;
}
65% {
    transform: scale(0.99);
    opacity: 1;
}
100% {
    transform: scale(1);
    opacity: 1;
}
`;

export const AllParticipantEtherpads = () => {
    const self = useSelfData();
    const participants = useParticipants();
    const { resources, variables } = useStepContent();

    const optionalHeaderText = variables?.variable1 ?? null;

    const orderNames = ["1st", "2nd", "3rd", "4th", "5th"];
    const etherpads: EtherpadDetails[] =
        resources?.resource1?.map((resource, idx) => {
            const etherpadName = resource.name ?? "";
            const participant = participants?.find(
                (participant) =>
                    participant.id === resource.sessionParticipantUuid,
            );
            const isSelf = participant?.id === self.sessionParticipantUuid;
            const baseUrl = resource.url;
            const systemId = isSelf
                ? resource.systemId
                : resource.readOnlySystemId;
                
            const url = getEtherpadUrl(baseUrl, systemId || "", self?.firstName ?? "");
            const currentlyViewing = {
                displayName: isSelf
                    ? "You"
                    : participant?.user?.firstName ?? "",
                avatarName:
                    `${participant?.user?.firstName} ${participant?.user?.lastName}` ??
                    "",
                avatarUrl: participant?.user?.avatarUrl ?? "",
                order: orderNames[idx],
            };

            return {
                url,
                etherpadName,
                headerType: "readonly nth numbered",
                sessionParticipantUuid: participant?.id ?? "",
                currentlyViewing,
            };
        }) ?? [];

    return (
        <Flex w="100%" direction="column" alignContent="center">
            {optionalHeaderText && (
                <Flex direction="column">
                    <Flex
                        w="fit-content"
                        p="10px 16px"
                        opacity={0}
                        bgColor="gray.900"
                        borderRadius="12px 12px 0px 0px"
                        animation={`${genieEffectInWithBounce} 0.65s ease-in-out forwards 1s`}
                        transformOrigin="bottom left"
                    >
                        <Text
                            padding="0px"
                            textAlign="center"
                            fontSize="15px"
                            fontWeight="400"
                            color="white"
                            lineHeight="17px"
                            fontFamily="Inter"
                        >
                            {optionalHeaderText}
                        </Text>
                    </Flex>
                </Flex>
            )}
            <Flex
                flex="1"
                gap="8px"
                justifyContent="center"
                mt={optionalHeaderText ? "16px" : "0px"}
            >
                {optionalHeaderText && (
                    <Flex
                        w="100%"
                        h="40px"
                        position="absolute"
                        top="36px"
                        borderTop="1px solid #D8DADF"
                        bgColor="#FCFCFD"
                    />
                )}
                {etherpads.map((etherpad) => (
                    <IFrameResource
                        key={etherpad.sessionParticipantUuid}
                        url={etherpad.url}
                        name={etherpad.etherpadName}
                        headerType={etherpad.headerType}
                        resourceType="etherpad"
                        currentlyViewing={etherpad.currentlyViewing}
                    />
                ))}
            </Flex>
        </Flex>
    );
};
