import { UseDisclosureReturn, useDisclosure } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { CameraPermissionsModal } from "../CameraPermissionsModal";
import { CameraAccessModal } from "../CameraAccessModal";
import { MicrophonePermissionsModal } from "../MicrophonePermissionsModal";

export interface ChimeModals {
    cameraAccessModal: UseDisclosureReturn;
    cameraPermissionsModal: UseDisclosureReturn;
    microphonePermissionsModal: UseDisclosureReturn;
}

export const ChimeModals = React.createContext<ChimeModals>(
    null as unknown as ChimeModals,
);

export const ChimeModalsProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const cameraAccessModal = useDisclosure();
    const cameraPermissionsModal = useDisclosure();
    const microphonePermissionsModal = useDisclosure();
    const context: ChimeModals = {
        cameraAccessModal,
        cameraPermissionsModal,
        microphonePermissionsModal,
    };
    return (
        <ChimeModals.Provider value={context}>
            <CameraPermissionsModal
                isOpen={cameraPermissionsModal.isOpen}
                onClose={cameraPermissionsModal.onClose}
            />
            <CameraAccessModal
                isOpen={cameraAccessModal.isOpen}
                onClose={cameraAccessModal.onClose}
            />
            <MicrophonePermissionsModal
                isOpen={microphonePermissionsModal.isOpen}
                onClose={microphonePermissionsModal.onClose}
            />
            {children}
        </ChimeModals.Provider>
    );
};
