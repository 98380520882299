import { AudioVideoObserver, VideoTileState } from "amazon-chime-sdk-js";
import { useAtom, useAtomValue } from "jotai";
import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useEffect,
} from "react";
import {
    currentVideoInputDeviceId,
    hasReachedVideoLimitState,
    isVideoEnabledState,
    meetingSession,
    tileIdState,
} from "../../immersionState";
import { useLocalVideoBlur } from "../hooks/useLocalVideoBlur";

export interface LocalVideoContext {
    tileId: number | null;
    isVideoEnabled: boolean;
    hasReachedVideoLimit: boolean;
    toggleVideo: () => void;
    isLoading: boolean;
}

export const LocalVideoContext = createContext<LocalVideoContext>(
    null as unknown as LocalVideoContext,
);

export const LocalVideoContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const [isVideoEnabled, setIsVideoEnabled] = useAtom(isVideoEnabledState);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [hasReachedVideoLimit, setHasReachedVideoLimit] = useAtom(
        hasReachedVideoLimitState,
    );
    const [tileId, setTileId] = useAtom(tileIdState);
    const currentVIDeviceId = useAtomValue(currentVideoInputDeviceId);
    const { getBackgroundBlurDevice } = useLocalVideoBlur();

    const meetingManager = useAtomValue(meetingSession);
    const audioVideo = meetingManager?.audioVideo;

    useEffect(() => {
        if (!audioVideo) {
            return;
        }

        const observer: AudioVideoObserver = {
            videoAvailabilityDidChange: (availability) => {
                if (!availability.canStartLocalVideo) {
                    setHasReachedVideoLimit(true);
                } else {
                    setHasReachedVideoLimit(false);
                }
                console.log(
                    `video availability changed: canStartLocalVideo ${availability.canStartLocalVideo}`,
                );
            },
        };
        audioVideo.addObserver(observer);

        return () => {
            audioVideo.removeObserver(observer);
        };
    }, [audioVideo, setIsVideoEnabled, setHasReachedVideoLimit]);

    useEffect(() => {
        if (hasReachedVideoLimit) {
            console.warn("Reach the number of maximum active videos");
        }
    }, [hasReachedVideoLimit]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const chosenVideoTransformDevice = await getBackgroundBlurDevice();
            if (audioVideo && chosenVideoTransformDevice && isVideoEnabled) {
                console.log(`[LocalVideo]: Enabling video`);
                await audioVideo?.startVideoInput(chosenVideoTransformDevice);
                audioVideo?.startLocalVideoTile();
                console.log(`[LocalVideo]: Started video tile`);
            }
            setIsLoading(false);
        })();
    }, [
        currentVIDeviceId,
        audioVideo,
        getBackgroundBlurDevice,
        isVideoEnabled,
    ]);

    const toggleVideo = useCallback(async (): Promise<void> => {
        setIsLoading(true);
        try {
            console.log(`[LocalVideo]: Toggling video`);
            if (isVideoEnabled) {
                await audioVideo?.stopVideoInput();
                setIsVideoEnabled(false);
            } else if (!hasReachedVideoLimit) {
                const device = await getBackgroundBlurDevice();
                if (audioVideo && device) {
                    await audioVideo?.startVideoInput(device);
                    audioVideo?.startLocalVideoTile();
                    setIsVideoEnabled(true);
                }
            } else {
                console.error(
                    "Video limit is reached and can not turn on more videos!",
                );
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Failed to toggle video");
            setIsLoading(false);
        }
    }, [
        audioVideo,
        isVideoEnabled,
        hasReachedVideoLimit,
        setIsVideoEnabled,
        meetingManager,
    ]);

    useEffect(() => {
        if (!audioVideo) {
            return;
        }

        const observer: AudioVideoObserver = {
            videoTileDidUpdate: (tileState: VideoTileState) => {
                if (
                    !tileState.localTile ||
                    !tileState.tileId ||
                    tileId === tileState.tileId
                ) {
                    return;
                }

                setTileId(tileState.tileId);
            },
        };
        audioVideo.addObserver(observer);

        return () => audioVideo.removeObserver(observer);
    }, [audioVideo, tileId, setTileId]);

    const context: LocalVideoContext = {
        tileId,
        isVideoEnabled,
        hasReachedVideoLimit,
        toggleVideo,
        isLoading,
    };

    return (
        <LocalVideoContext.Provider value={context}>
            {children}
        </LocalVideoContext.Provider>
    );
};
