import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import {
    useHasVisitedGroupSession,
    useImmersion,
    useIsFirstSessionWebflow,
    useIsLiveOnboarding,
    useSessionParticipantAsLoader,
} from "../hooks/useImmersion";
import { useNavigate } from "react-router-dom";
import { useAccount } from "../../hooks/useAccount";
import { useNextStepNumber, useStepUrl } from "../hooks/useSessionContent";
import { Loading } from "../../Loading";
import { FirstSessionCoverSlide } from "./FirstSessionCoverSlide";

export const ImmersionCoverSlide = () => {
    const immersion = useImmersion();
    const navigate = useNavigate();
    const account = useAccount();
    const isLiveOnboarding = useIsLiveOnboarding();
    const isFirstSessionWebflow = useIsFirstSessionWebflow();
    const hasVisitedSession = useHasVisitedGroupSession();
    const nextStepNumber = useNextStepNumber();
    const sessionParticipantLoader = useSessionParticipantAsLoader();
    const enterImmersionUrl = useStepUrl(nextStepNumber);

    if (!immersion || !account.data) {
        return <div>Error...</div>;
    }

    if (
        sessionParticipantLoader?.isLoading ||
        !enterImmersionUrl ||
        hasVisitedSession === undefined
    ) {
        return <Loading />;
    }

    if (!isLiveOnboarding && !isFirstSessionWebflow && !hasVisitedSession) {
        return <FirstSessionCoverSlide />;
    }

    return (
        <Flex
            w="100%"
            justifyContent="center"
            flexDirection="row"
            alignSelf="center"
        >
            <Flex flexDirection="column" alignItems="center">
                <Text align="center" fontWeight="semibold" color="blue.300">
                    {immersion.data?.immersion.topic.name.toLocaleUpperCase()}
                </Text>
                <Heading textAlign="center" fontSize="48" fontFamily="Griff">
                    {immersion.data?.immersion.tactic}
                </Heading>
                <Text marginY="50px">
                    👋 Welcome{" "}
                    <b>{`${account.data.firstName} ${account.data.lastName}`}</b>
                    !
                </Text>
                <Button
                    data-testid="enter-immersion-button"
                    borderRadius="8px"
                    w="200px"
                    colorScheme="blue"
                    size="lg"
                    onClick={() => {
                        navigate(enterImmersionUrl);
                    }}
                >
                    Enter Session
                </Button>
            </Flex>
        </Flex>
    );
};
