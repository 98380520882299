import { atom } from "recoil";
import { Room } from "twilio-video";
import {
    DefaultMeetingSession,
    EventAttributes,
    EventName,
} from "amazon-chime-sdk-js";
import {
    BaseMetadata,
    BaseUserMeta,
    Json,
    LsonObject,
    Room as LiveblocksRoom,
} from "@liveblocks/client";
import { Presence } from "shared/LiveblocksTypes";
import { RosterType } from "./video_chime/hooks/useRoster";

export const videoRoom = atom<Room | null>({
    key: "videoRoom",
    default: null,
    dangerouslyAllowMutability: true,
});
/**
 * CHIME RELATED ATOMS
 */

type MeetingEventProviderContextType =
    | {
          name: EventName;
          attributes: EventAttributes;
      }
    | undefined;

export const meetingEventState = atom<MeetingEventProviderContextType>({
    key: "meetingEventState",
    default: undefined,
});

export const meetingSession = atom<DefaultMeetingSession | null>({
    key: "meetingSession",
    default: null,
    dangerouslyAllowMutability: true,
});

export const isVideoEnabledState = atom({
    key: "isVideoEnabledState",
    default: true,
});

export const isAudioEnabledState = atom({
    key: "isAudioEnabledState",
    default: true,
});

export const isBackgroundBlurEnabledState = atom({
    key: "isBackgroundBlurEnabledState",
    default: false,
});

export const backgroundBlurSupportedState = atom<boolean | undefined>({
    key: "backgroundBlurSupportedState",
    default: undefined,
});

export const isNoiseCancellingEnabledState = atom({
    key: "isNoiseCancellingEnabledState",
    default: true,
});

export const hasReachedVideoLimitState = atom({
    key: "hasReachedVideoLimitState",
    default: false,
});

export const tileIdState = atom({
    key: "tileIdState",
    default: null as number | null,
});

export const rosterState = atom<RosterType>({
    key: "rosterState",
    default: {},
});

export const chimeMicTooltip = atom<string | null>({
    key: "chimeMicTooltip",
    default: null,
});

/**
 * END OF CHIME RELATED ATOMS
 */

export const liveblocksRoom = atom<LiveblocksRoom<
    Presence,
    LsonObject,
    BaseUserMeta,
    Json,
    BaseMetadata
> | null>({
    key: "liveblocksRoom",
    default: null,
});

export const stepSidebarOpen = atom<boolean>({
    key: "stepSidebarOpen",
    default: false,
});

export const micEnabled = atom<boolean>({
    key: "micEnabled",
    default: true,
});

export const micTooltip = atom<string | null>({
    key: "micTooltip",
    default: null,
});

export const streamSource = atom<StreamSource>({
    key: "streamSource",
    default: "sparkwise",
});

export const newTabHelpUrl = atom<string | null>({
    key: "newTabHelpUrl",
    default: null,
});

export const currentAudioInputDeviceId = atom<string | null>({
    key: "currentAudioInputDeviceId",
    default: null,
});
export const currentAudioOutputDeviceId = atom<string | null>({
    key: "currentAudioOutputDeviceId",
    default: null,
});
export const currentVideoInputDeviceId = atom<string | null>({
    key: "currentVideoInputDeviceId",
    default: null,
});

export const localVideoTrackState = atom<MediaStreamTrack | null>({
    key: "localVideoTrack",
    default: null,
});

export const localAudioTrackState = atom<MediaStreamTrack | null>({
    key: "localAudioTrack",
    default: null,
});

export const videoPermissionState = atom<
    "prompt" | "denied" | "granted" | null
>({
    key: "videoPermissionState",
    default: null,
});

export const audioPermissionState = atom<
    "prompt" | "denied" | "granted" | null
>({
    key: "audioPermissionState",
    default: null,
});

/**
 * List of media devices
 */
export const localDevicesState = atom<MediaDeviceInfo[]>({
    key: "localDevicesState",
    default: [],
});

export enum ProductTourStep {
    OrientationVideo = 0,
    Intro = 1,
    Workspace = 2,
    Video = 3,
    ProgressTracker = 4,
    Instructions = 5,
    Timer = 6,
    End = 7,
}
export const productTourStep = atom<ProductTourStep>({
    key: "productTourStep",
    default: ProductTourStep.OrientationVideo,
});

export type StreamSource = "sparkwise" | "cloudflare";
